import React from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import Dashboard from './pages/admin/dashboard';
import UserDashboard from './pages/user/dashboard';
import {
  Route,
  Routes,
} from "react-router-dom";
import UserManagement from './pages/admin/usermanagement';
import User from './pages/user/index';
import Admin from './pages/admin';
import AgencyManagement from './pages/admin/agencymanagment';
import Transaction from './pages/admin/tranction';
import Support from './pages/admin/support';
import Usermanagementsettings from './pages/admin/usermanagmentsetting';
import BuySell from './pages/user/BuySell/BuySell';
import SignUp from './auth/signup/signup';
import Login from './auth/login/login';
import ForgotEmail from './auth/forgotemail'
import FeedbackAndSuport from './pages/user/feedback';
import MyAssets from './pages/user/myassets';
import SwapCrypto from './pages/user/swapcrypto';
import UserTransaction from './pages/user/transaction';
import Verification from './verification';
import UserProfile from './pages/user/profile';
import Ticket from './pages/admin/ticket'
import UserTicket from './pages/user/ticket'
import AssestsManage from './pages/user/assestmanage'
import { ConfigProvider } from 'antd';
import { useSelector } from 'react-redux'


function App() {
  const dark = useSelector(state => state?.theme?.value);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgContainer: dark === 'dark' ? '#141f38' : '#fff',
          colorBgContainerDisabled: dark === 'dark' ? '#141f38' : 'rgba(0, 0, 0, 0.04)',
          colorBorder: dark === 'dark' ? '#334155' : '#d9d9d9',
          colorText: dark === 'dark' ? '#94a3b8' : 'rgba(0, 0, 0, 0.8)',
          colorTextDisabled: dark === 'dark' ? '#94a3b8' : 'rgba(0, 0, 0, 0.5)',
          colorTextPlaceholder: dark === 'dark' ? '#94a3b8' : 'rgba(0, 0, 0, 0.5)',
          colorPrimary: '#6c5dd3',
          colorPrimaryHover: '#6c5dd3',
          colorLinkActive: '#6c5dd3',
          colorLinkHover: '#6c5dd3',
          colorBgElevated: dark === 'dark' ? '#141f38' : '#fff',
          fontFamily: 'Nunito Sans'
        },
        components: {
          Input: {
            activeBorderColor: '#6c5dd3',
            hoverBorderColor: '#6c5dd3',
            activeShadow: '#6c5dd3'
          },
          Select: {
            optionActiveBg: dark === 'dark' ? '#2b374b' : 'rgba(0, 0, 0, 0.04)',
            optionSelectedBg: dark === 'dark' ? '#343360' : '#e6f4ff',
            selectorBg: dark === 'dark' ? '#141f38' : '#fff'
          },
          Button: {
            defaultBorderColor: dark === 'dark' ? '#334155' : '#d9d9d9',
            defaultColor: dark === 'dark' ? '#fff' : 'rgba(0, 0, 0, 0.88)'
          },
          Modal: {
            contentBg: dark === 'dark' ? '#0f172a' : '#fff'
          }
        },
      }}
    >
      <Routes>
        <Route path='/verification' element={<Verification />} />
        <Route index element={<Login />} />
        <Route path='login' element={<Login />} />
        <Route path='signup' element={<SignUp />} />
        <Route path='forgot-password' element={<ForgotEmail />} />

        <Route path='/admin' element={<Admin />}>
          <Route index element={<Dashboard />} />
          <Route path='dashboard' element={<Dashboard />} />
          <Route path='managment' element={<UserManagement />} />
          <Route path='managment/:id' element={<Usermanagementsettings />} />
          <Route path='agencymanagement' element={<AgencyManagement />} />
          <Route path='transaction' element={<Transaction />} />
          <Route path='support' element={<Support />} />
          <Route path='ticket/:id' element={<Ticket />} />
        </Route>
        <Route path='/user' element={<User />}>
          <Route index element={<UserDashboard />} />
          <Route path='dashboard' element={<UserDashboard />} />
          <Route path='buysell' element={<BuySell />} />
          <Route path='support' element={<FeedbackAndSuport />} />
          <Route path='assets' element={<MyAssets />} />
          <Route path='assets/manage' element={<AssestsManage />} />
          <Route path='swapcrypto' element={<SwapCrypto />} />
          <Route path='transaction' element={<UserTransaction />} />
          <Route path='profile' element={<UserProfile />} />
          <Route path='ticket/:id' element={<UserTicket />} />
        </Route>
      </Routes>
    </ConfigProvider>

  );
}
export default App;
