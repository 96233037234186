import { createSlice } from '@reduxjs/toolkit';



const themeSlice = createSlice({
    name: 'theme',
    initialState: { value: false },
    reducers: {
        chnageTheme: (state, action) => {
            state.value = action.payload
        }
    }
})

export const { chnageTheme } = themeSlice.actions;
export default themeSlice.reducer;