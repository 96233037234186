import React, { useEffect, useState } from 'react'
import logo from '../assests/icons/Group.png'
import darkmode from '../assests/icons/Vector2.png'
import language from '../assests/icons/Frame.png'
import avatar from '../assests/icons/Oval.png'
import email from '../assests/icons/verificationemail.png'
import app from '../assests/icons/verificationapp.png'
import { MdKeyboardArrowDown, MdOutlineSmartphone } from 'react-icons/md'
import { Button, Dropdown, Input, InputNumber, Modal, Progress, Result, notification } from 'antd'
import arrow from '../assests/icons/backarrow.png'
import OtpInput from 'react-otp-input';
import lock from '../assests/icons/lock.png'
import { IoLocationSharp } from 'react-icons/io5'
import { BiBell, BiLogOut, BiSolidCity } from 'react-icons/bi'
import verimg from '../../src/assests/images/convert2.png'
import uploadimg from '../../src/assests/icons/uploadicon.png'
import { BsBell, BsFillMoonStarsFill, BsFillSunFill, BsSunFill, BsUpload } from 'react-icons/bs';
import veraddress from '../../src/assests/images/convert1.png'
import congratsimg from '../../src/assests/images/convert.png'
import { Upload } from 'antd';
import { Link } from 'react-router-dom'
import handlePost from '../utils/Post'
import { BASE_URL } from '../utils/config'
import handleGet from '../utils/Get'
import { HiLanguage } from 'react-icons/hi2'
import { LiaLanguageSolid } from 'react-icons/lia'
import { useDispatch } from 'react-redux';
import { chnageTheme } from '../redux/reducer/themeSlice'
import { useNavigate } from 'react-router-dom'
import Logo from '../assests/images/PayBack.png';
import axios from 'axios'


const Verification = () => {

    const [screen, setScreen] = useState({ first: true, second: false, third: false, fourth: false, fifth: false, sixth: false })
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisibleSecond, setIsModalVisibleSecond] = useState(false);
    const [file, setFile] = useState({ kyc_document_id: '', kyc_document_address: '' });
    const [otp, setOtp] = useState('')
    const [loading, setLoading] = useState({ code: false, user: false })
    const [data, setData] = useState({ name: '', email: '', phone_number: '', address: '', city: '', country: '', postal_code: '' })
    const progress = 100 / 6;
    const user = JSON.parse(localStorage.getItem('user'));
    const [dark, setDark] = useState(localStorage.getItem('theme'));
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const showModal = () => {
        setIsModalVisible(true);
    };

    const showModalSecond = () => {
        setIsModalVisibleSecond(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
        setScreen({ ...screen, })
    };

    const handleOkSecond = async () => {
        const response = await handlePost(`${BASE_URL}user/upload/kyc`, file);
        if (response) {
            setScreen({ ...screen, fifth: false, sixth: true })
        }
    }

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleGetCode = async () => {
        setLoading({ ...loading, code: true });
        try {
            const code = await handleGet(`${BASE_URL}2fa/sendcode`);
            console.log(code)
            if (code) {
                notification.success({ message: code?.message })
                setScreen({ ...screen, first: false, second: true })
            }
            else {
                notification.error({ message: 'Request failed ' })
            }
            setLoading({ ...loading, code: false })
        }
        catch (error) {
            setLoading({ ...loading, code: false })
            console.log(error);
        }
    };

    const handleVerifyCode = async () => {
        const response = await handlePost(`${BASE_URL}2fa/verifycode`, { code: otp })
        if (response) {
            setScreen({ ...screen, second: false, third: true })
        }
    };

    const handleUpdateUser = async () => {
        setLoading({ ...loading, user: true });
        const formData = {
            email: user?.email,
            name: data?.name,
            'details[phone_number]': data?.phone_number,
            'details[address]': data?.address,
            'details[city]': data?.city,
            'details[country]': data?.country,
            'details[postal_code]': data?.postal_code
        }
        try {
            const response = await handlePost(`${BASE_URL}update/user/${user?.id}`, formData)
            if (response) {
                setScreen({ ...screen, third: false, fourth: true })
            }
            setLoading({ ...loading, user: false });
        } catch (error) {
            console.log(error)
            setLoading({ ...loading, user: false });
        }
    };

    const handleLogout = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${BASE_URL}logout`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'type': 'Bearer'
                }
            })
            console.log('Logout successful');
            localStorage.removeItem('token');
            localStorage.removeItem('timestamp');
            localStorage.removeItem('time');
            navigate('/login');
            return;
        }
        catch (err) {
            console.log(err)
            // if (err?.response?.data?.message === "Unauthenticated.") {

            // }
        }
    };

    const handleDarkMode = () => {
        setDark(prev => prev === 'dark' ? 'light' : 'dark');
    };

    const handleCompleteVerification = async () => {
        setLoading({ ...loading, user: true });
        try {
            const response = await handleGet(`${BASE_URL}get/user/detail/${user?.id}`);
            localStorage.setItem('user', JSON.stringify(response?.user));
            setLoading({ ...loading, user: false });
            navigate('/user');
        }
        catch (err) {
            console.log(err);
            setLoading({ ...loading, user: false });
        };
    };

    useEffect(() => {
        if (dark === 'dark') {
            dispatch(chnageTheme(dark));
            document.documentElement.classList.add('dark');
            localStorage.setItem('theme', dark);
        }
        else {
            dispatch(chnageTheme(dark));
            document.documentElement.classList.remove('dark');
            localStorage.setItem('theme', dark);
        }
    }, [dark]);

    return (
        <div className='overflow-x-hidden dark:bg-dark'>

            <div className='flex h-20 px-8' >
                <div className='w-full h-full flex items-center gap-6 py-2'>
                    <img className='w-12 h-12' src={Logo} alt="logo" style={{width:"10%"}} />
                    <div className='h-full w-[1px] bg-line mx-2' />
                    <div className='flex h-full items-center'>
                        <p className='text-sm text-gray-400 dark:text-white font-nunito'>Step 1:</p>
                        <p className='text-[#000] dark:text-dark-text font-nunito mx-1 font-semibold'>Two-factor Authentication</p>
                        <MdKeyboardArrowDown size={20} className='mt-[2px]' />
                    </div>
                </div>
                <ul className='h-full flex gap-6 items-center'>
                    <li onClick={handleDarkMode} className='w-10 h-10 rounded-full cursor-pointer dark:border dark:border-[#334155] dark:bg-dark bg-theme-white hidden min-[525px]:flex items-center justify-center shadow-xl'>
                        <p className='dark:text-white text-[#7EBA00]'>{dark !== 'dark' ? <BsSunFill size={24} /> : < BsFillMoonStarsFill />}</p>
                    </li>
                    <li className='w-10 h-10 rounded-full dark:border dark:border-[#334155] bg-theme-white dark:bg-dark hidden min-[525px]:flex cursor-pointer items-center justify-center shadow-xl'>
                        <p className='dark:text-white text-[#7EBA00]'><LiaLanguageSolid size={24} /></p>
                    </li>
                    <li className='w-10 h-10 rounded-full dark:border dark:border-[#334155] bg-theme-white dark:bg-dark hidden min-[525px]:flex cursor-pointer items-center justify-center shadow-xl'>
                        <p className='dark:text-white text-[#7EBA00]'> <BsBell size={24} /></p>
                    </li>
                    <Dropdown placement='bottomLeft' trigger={['click']}
                        dropdownRender={() => (
                            <div className='dark:bg-dark dark:border dark:border-[#334155] bg-theme-white cursor-pointer w-40 shadow-md h-fit rounded-lg p-2'>
                                <li onClick={handleLogout} className='flex gap-2 items-center h-10 hover:bg-hover dark:hover:bg-dark-hover rounded-md transition-all duration-300 p-2'>
                                    <BiLogOut size={24} /><p className='font-nunito text-lg font-semibold'>Logout</p>
                                </li>
                                <li onClick={handleDarkMode} className='flex min-[525px]:hidden gap-2 items-center h-10 hover:bg-hover dark:hover:bg-dark-hover rounded-md transition-all duration-300 p-2'>
                                    <BsSunFill size={24} />
                                    <p className='font-nunito text-lg font-semibold'>Darkmode</p>
                                </li>
                                <li className='flex min-[525px]:hidden gap-2 items-center h-10 hover:bg-hover dark:hover:bg-dark-hover rounded-md transition-all duration-300 p-2'>
                                    <HiLanguage size={24} />
                                    <p className='font-nunito text-lg font-semibold'>Language</p>
                                </li>
                                <li className='flex min-[525px]:hidden gap-2 items-center h-10 hover:bg-hover dark:hover:bg-dark-hover rounded-md transition-all duration-300 p-2'>
                                    <BiBell size={24} />
                                    <p className='font-nunito text-lg font-semibold'>Notification</p>
                                </li>
                            </div>
                        )}
                    >
                        <li className='w-10 h-10 rounded-full bg-theme-white dark:border dark:border-[#334155] dark:bg-dark flex cursor-pointer items-center justify-center shadow-xl'>
                            <img className='w-6 h-6' src={avatar} alt="item" />
                        </li>
                    </Dropdown>
                </ul>
            </div>
            <Progress status='success' percent={screen.first ? progress : screen.second ? progress * 2 : screen.third ? progress * 3 : screen.fourth ? progress * 4 : screen.fifth ? progress * 5 : screen.sixth && progress * 6} type='line' strokeColor='#9185DE' size='small' showInfo={false} />
            {
                screen?.first &&
                <>
                    <div className='w-full flex flex-col items-center my-8 p-7 md:p-9'>
                        <div className='flex flex-col items-center'>
                            <p className='md:text-2xl dark:text-white sm:text-xl text-lg font-nunito font-bold'>Enable two-factor authentication</p>
                            <p className='font-nunito dark:text-dark-text text-sm text-gray-400 my-2'>Add an extra layer of security to your account</p>
                            <div className='flex gap-4 my-4'>
                                <div className='max-w-sm w-full h-64 p-2 rounded-xl border-1 border-gray-400 flex flex-col items-center' >
                                    <img className='w-48 h-36 mt-3' src={app} alt="app" />
                                    <p className='font-nunito dark:text-white text-xl font-bold mt-2'>With Email</p>
                                    <p className='text-sm text-gray-400 font-nunito font-medium'>We will send a confirmation code to your email address</p>
                                </div>
                            </div>
                            <Button loading={loading?.code} onClick={handleGetCode} className='w-fit h-fit px-12 py-3 font-nunito text-sm text-white my-4 rounded-xl bg-hoverUser'>Continue</Button>
                        </div>
                    </div>
                </>
            }
            {
                screen?.second &&
                <div>
                    <div className='w-full h-12 items-center flex mx-[30px] gap-2'>
                        <img onClick={() => setScreen({ ...screen, second: false, first: true })} className='w-8 h-8' src={arrow} alt="" />
                        <p className='font-nunito font-medium dark:text-white'>Go Back</p>
                    </div>
                    <div className='w-full flex flex-col items-center'>
                        <img className='w-14 mb-8 h-14' src={lock} alt="" />
                        <p className='text-2xl font-nunito font-bold dark:text-white'>Enter your Code</p>
                        <p className='font-nunito text-sm text-gray-400 dark:text-dark-text my-2 leading-3'>We sent you a code to your email</p>
                        <OtpInput
                            value={otp}
                            containerStyle={{
                                margin: "auto",
                                marginBottom: "20px",
                                marginTop: 32,
                                justifyContent: "center",
                            }}
                            onChange={setOtp}
                            inputStyle={{
                                width: "48px",
                                padding: "12px",
                                border: "1px solid #ACACAC",
                                borderRadius: 12
                            }}
                            numInputs={6}
                            renderSeparator={
                                <span style={{ marginInline: "5px" }}>  </span>
                            }
                            renderInput={(props) => <input {...props} />}
                        />
                        <Button onClick={handleVerifyCode} className='w-fit h-fit px-12 py-3 font-nunito text-sm text-white mt-20 rounded-xl bg-hoverUser'>Continue</Button>
                    </div>
                </div>
            }
            {
                screen?.third &&
                <div className='p-2'>
                    <div className='w-full h-12 items-center flex mx-[30px] gap-2'>
                        <img onClick={() => setScreen({ ...screen, third: false, second: true })} className='w-8 h-8 cursor-pointer' src={arrow} alt="" />
                        <p className='font-nunito font-medium dark:text-white'>Go Back</p>
                    </div>
                    <div className='w-full flex flex-col items-center'>
                        <p className='text-2xl font-nunito font-bold dark:text-white'>Tell us about your self</p>
                        <p className='font-nunito text-sm dark:text-dark-text text-gray-400 my-2 leading-3'>We need this information to verify your identity</p>
                        <div className='w-full max-w-xl'>
                            <p className='font-nunito my-0.5 font-medium dark:text-dark-text'>Username</p>
                            <Input value={data?.name} onChange={e => setData({ ...data, name: e.target.value })} className='mb-3' size='large' placeholder='Jane Eyre' />
                            <p className='font-nunito my-0.5 font-medium dark:text-dark-text'>Phone Number</p>
                            <InputNumber value={data?.phone_number} onChange={e => setData({ ...data, phone_number: e })} prefix={<MdOutlineSmartphone size={20} color='gray' />} className='w-full mb-3' type='number' size='large' placeholder='i.e 03xxxxxxxxxxxxx' />
                            <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-2'>
                                <div className='col-span-1'>
                                    <p className='font-nunito my-0.5 font-medium dark:text-dark-text'>Address</p>
                                    <Input value={data?.address} onChange={e => setData({ ...data, address: e.target.value })} prefix={<IoLocationSharp size={20} color='gray' />} className='mb-3' size='large' placeholder='Address' />
                                </div>
                                <div className='col-span-1'>
                                    <p className='font-nunito my-0.5 font-medium dark:text-dark-text'>City</p>
                                    <Input value={data?.city} onChange={e => setData({ ...data, city: e.target.value })} prefix={<BiSolidCity size={20} color='gray' />} className='mb-3' size='large' placeholder='City' />
                                </div>
                            </div>
                            <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-2'>
                                <div className='col-span-1'>
                                    <p className='font-nunito my-0.5 font-medium dark:text-dark-text'>Country</p>
                                    <Input value={data?.country} onChange={e => setData({ ...data, country: e.target.value })} className='mb-3' size='large' placeholder='Country' />
                                </div>
                                <div className='col-span-1'>
                                    <p className='font-nunito my-0.5 font-medium dark:text-dark-text'>Postal Code</p>
                                    <Input value={data?.postal_code} onChange={e => setData({ ...data, postal_code: e.target.value })} className='mb-3' size='large' placeholder='Postal Code' />
                                </div>
                            </div>
                        </div>
                        <Button loading={loading?.user} onClick={handleUpdateUser} className='w-fit h-fit px-12 py-3 font-nunito text-sm text-white my-2 rounded-xl bg-hoverUser'>Continue</Button>
                    </div>
                </div>
            }
            {
                screen?.fourth &&
                <div>
                    <div className='w-full h-12 items-center flex mx-[30px] gap-2'>
                        <img onClick={() => setScreen({ ...screen, fourth: false, third: true })} className='w-8 h-8 cursor-pointer' src={arrow} alt="" />
                        <p className='font-nunito font-medium dark:text-white'>Go Back</p>
                    </div>
                    <div className='justify-center text-center font-nunito'>
                        <h2 className='font-bold text-3xl dark:text-white text-[#000000]'>Verify your identity</h2>
                        <p className='text-[#6B6B6B] dark:text-dark-text'>Upload a photo of your ID card, passport, or driving license. Make sure it is valid and not expired</p>
                        <img className='justify-center m-auto w-802 h-80' src={verimg} alt="" />
                        <div>
                            <button
                                className='m-auto flex w-fit h-fit px-12 py-3 font-nunito text-sm text-white my-2 rounded-xl bg-hoverUser'
                                onClick={showModal}
                            >
                                <img className='me-2 w-4 h-4' src={uploadimg} alt="" />
                                Upload Document
                            </button>
                            <Modal
                                centered={true}
                                title="Upload Document"
                                open={isModalVisible}
                                onOk={handleOk}
                                onCancel={handleCancel}
                                footer={[
                                    <Button key="back" onClick={handleCancel}>
                                        Cancel
                                    </Button>,
                                    // <Button onClick={handleOk}>
                                    //     Ok
                                    // </Button>,
                                ]}
                            >
                                <Upload.Dragger beforeUpload={e => { setFile({ ...file, kyc_document_id: e }); setScreen({ ...screen, fourth: false, fifth: true }) }} showUploadList={false}>
                                    <p className="d-flex justify-content-center align-items-center">
                                        <BsUpload size={20} />
                                    </p>

                                    <div className='font-nunito mt-2'>
                                        <p className="text-[#6B6B6B] text-lg">Drop files to upload </p>
                                        <p className='text-[#6B6B6B] text-sm'>OR</p>
                                        <p className="text-[#6B6B6B] text-lg">
                                            Select Files
                                        </p>
                                    </div>
                                </Upload.Dragger>
                            </Modal>
                        </div>
                    </div>

                </div>
            }
            {
                screen?.fifth &&
                <div>
                    <div className='w-full h-12 items-center flex mx-[30px] gap-2'>
                        <img onClick={() => setScreen({ ...screen, fifth: false, fourth: true })} className='w-8 h-8' src={arrow} alt="" />
                        <p className='font-nunito font-medium dark:text-white'>Go Back</p>
                    </div>
                    <div className='justify-center text-center font-nunito'>
                        <h2 className='font-bold text-3xl text-[#000000] dark:text-white'>Verify your address</h2>
                        <p className='text-[#6B6B6B] dark:text-dark-text'>Upload a proof of address document, such as a utility bill or bank statement</p>
                        <img className='justify-center m-auto w-802 h-80' src={veraddress} alt="" />
                        <div>
                            <button
                                className='m-auto flex w-fit h-fit px-12 py-3 font-nunito text-sm text-white my-2 rounded-xl bg-hoverUser'
                                onClick={showModalSecond}
                            >
                                <img className='me-2 w-4 h-4' src={uploadimg} alt="" />
                                Upload Document
                            </button>
                            <Modal
                                centered={true}
                                title="Upload Document"
                                open={isModalVisibleSecond}
                                onOk={handleOkSecond}
                                onCancel={() => setIsModalVisibleSecond(false)}
                                footer={[
                                    <Button key="back" onClick={() => setIsModalVisibleSecond(false)}>
                                        Cancel
                                    </Button>,
                                    <Button onClick={handleOkSecond}>
                                        Ok
                                    </Button>,
                                ]}
                            >
                                <Upload.Dragger beforeUpload={e => setFile({ ...file, kyc_document_address: e })} showUploadList={false}>
                                    {!file?.kyc_document_address ? <>
                                        <p className="d-flex justify-content-center align-items-center">
                                            <BsUpload size={20} />
                                        </p>

                                        <div className='font-nunito mt-2'>
                                            <p className="text-[#6B6B6B] text-lg">Drop files to upload </p>
                                            <p className='text-[#6B6B6B] text-sm'>OR</p>
                                            <p className="text-[#6B6B6B] text-lg">
                                                Select Files
                                            </p>
                                        </div>
                                    </> :
                                        <Result status='success' />
                                    }
                                </Upload.Dragger>
                            </Modal>
                        </div>
                    </div>

                </div>
            }
            {
                screen?.sixth &&
                <div>
                    <div className='w-full h-12 items-center flex mx-[30px] gap-2'>
                        <img className='w-8 h-8' src={arrow} alt="" />
                        <p className='font-nunito font-medium dark:text-white'>Go Back</p>
                    </div>
                    <div className='justify-center text-center font-nunito'>
                        <h2 className='font-bold text-3xl text-[#000000] dark:text-white'>Congrats! You're all set</h2>
                        <p className='text-[#6B6B6B] dark:text-dark-text'>Awesome, your information was submitted successfully for review.</p>
                        <img className='justify-center m-auto  h-80' src={congratsimg} alt="" />
                        <h2 className='font-bold text-2xl text-[#000000] dark:text-white'>You're all set!</h2>
                        <p className='text-[#6B6B6B] dark:text-dark-text'>We will review your information and approve your account shortly.</p>
                        <div>
                            <Button loading={loading?.user} onClick={handleCompleteVerification} className='mt-3 m-auto flex w-fit h-fit px-12 py-3 font-nunito text-sm text-white my-2 rounded-xl bg-hoverUser'>
                                Go to Dashboard
                            </Button>
                        </div>
                    </div>

                </div>
            }
        </div>
    )
}

export default Verification