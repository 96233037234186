import { BASE_URL } from "./config";
import axios from "axios";

const handleRefreshToken = async () => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.post(`${BASE_URL}refresh-token`, '', {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'type': 'Bearer'
            }
        })
        if (response) {
            const token = response?.data?.authorisation?.token;
            await localStorage.setItem('token', token)
            return true;
        }
    }
    catch (err) {
        console.log(err)
    }
}

export default handleRefreshToken;