import React from 'react'
// import "./index.css"
import search from '../../assests/icons/Search.png'
import user from '../../assests/userimg.png'
const Card = ({ details }) => {
    const userCards = [
        {
            name: 'User 1',
            email: 'user1@example.com',
            imageSrc: user,
        },
        {
            name: 'User 2',
            email: 'user2@example.com',
            imageSrc: user,
        },
        {
            name: 'User 3',
            email: 'user3@example.com',
            imageSrc: user,
        },
        {
            name: 'User 4',
            email: 'user4@example.com',
            imageSrc: user,
        },
        {
            name: 'User 5',
            email: 'user5@example.com',
            imageSrc: user,
        },
        {
            name: 'User 6',
            email: 'user6@example.com',
            imageSrc: user,
        },
        {
            name: 'User 7',
            email: 'user7@example.com',
            imageSrc: user,
        },
        {
            name: 'User 8',
            email: 'user8@example.com',
            imageSrc: user,
        },
        {
            name: 'User 9',
            email: 'user9@example.com',
            imageSrc: user,
        },
        {
            name: 'User 10',
            email: 'user10@example.com',
            imageSrc: user,
        },
        {
            name: 'User 11',
            email: 'user11@example.com',
            imageSrc: user,
        },
        {
            name: 'User 12',
            email: 'user12@example.com',
            imageSrc: user,
        },
        {
            name: 'User 13',
            email: 'user13@example.com',
            imageSrc: user,
        },
        {
            name: 'User 14',
            email: 'user14@example.com',
            imageSrc: user,
        },
        {
            name: 'User 15',
            email: 'user15@example.com',
            imageSrc: user,
        },
        {
            name: 'User 16',
            email: 'user16@example.com',
            imageSrc: user,
        },
        {
            name: 'User 17',
            email: 'user17@example.com',
            imageSrc: user,
        },
        {
            name: 'User 18',
            email: 'user18@example.com',
            imageSrc: user,
        },
        {
            name: 'User 19',
            email: 'user19@example.com',
            imageSrc: user,
        },
        {
            name: 'User 20',
            email: 'user20@example.com',
            imageSrc: user,
        },
    ];
    return (
        <>
            <div className='w-full max-w-xs h-[340px] transition-all hover:shadow-xl duration-300 dark:bg-dark dark:border dark:border-[#334155] hover:border-[#6c5dd3] bg-[#fff] rounded-[10px] border-1 border-gray-400'>
                <div className='h-20 w-full flex justify-center pl-9 flex-col dark:bg-[#141f38] bg-[#E6E8F0] rounded-t-[10px]'>
                    <p className='text-base font-medium leading-6 font-nunito dark:text-white'>Offline</p>
                    <p className='text-[#6B6B6B] text-sm font-nunito dark:text-gray-400 font-medium'>Last Seen: 2023-09-14 19:04:54</p>
                </div>
                <div className='flex items-center flex-col my-4'>
                    <div className='w-20 h-20 rounded-full dark:border dark:border-[#334155] dark:bg-[#141f38] bg-[#D9D9D9]'>
                    </div>
                    <p className='text-lg font-bold font-nunito dark:text-white mt-1'>{details.name}</p>
                    <p className='font-nunito text-sm font-medium dark:text-gray-400'>{details.email}</p>
                </div>
                <div className='w-full flex justify-center'>
                    <button className='w-[80%] h-10 dark:bg-[#141f38] bg-[#E6E8F0] border-1 border-gray-400 dark:border-[#334155] rounded-lg'>
                        <p className='text-base dark:text-white text-[#081735]'>Manage User</p>
                    </button>
                </div>
            </div>
        </>


    )
}
export default Card;