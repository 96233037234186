import { notification } from "antd";
import axios from "axios"
import handleRefreshToken from "./RefreshToken";

const handlePost = async (url, formData) => {
    try {
        const token = localStorage.getItem('token');
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'multipart/form-data',
            'type': 'Bearer'
        }
        const response = await axios.post(url, formData, { headers });
        if (response) {
            console.log(response)
            notification.success({
                message: response?.data?.message
            })
            return response;
        }
    }
    catch (error) {
        console.log(error)
        // console.log(error)
        // if (error?.response?.data?.message === "Unauthenticated.") {
        //     const refreshToken = await handleRefreshToken();
        //     if (refreshToken) {
        //         const token = localStorage.getItem('token');
        //         const headers = {
        //             'Authorization': 'Bearer ' + token,
        //             'Content-Type': 'multipart/form-data',
        //             'type': 'Bearer'
        //         }
        //         const response = await axios.post(url, formData, { headers });
        //         if (response) {
        //             console.log(response)
        //             notification.success({
        //                 message: response?.data?.message
        //             })
        //             return response;
        //         }
        //     }
        //     return;
        // }
        if (error?.response?.data?.errors) {
            const errorMessage = Object.keys(error?.response?.data?.errors)?.map(item => error?.response?.data?.errors[item]?.[0])
            errorMessage?.map(item => {
                notification.error({
                    message: item
                })
            })
        }
        else if (error?.response?.data?.error) {
            notification.error({ message: error?.response?.data?.error })
        }
        else if (error?.response?.data) {
            notification.error({
                message: error?.response?.data?.message
            })
        }
        else {
            notification.error({
                message: error?.response?.data?.error
            })
        }
    }
}
export default handlePost;