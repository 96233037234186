import dayjs from 'dayjs';
import React from 'react';
import ReactApexChart from 'react-apexcharts';

const MyChart = ({ graphData, name }) => {


    const date = graphData?.map(item => dayjs(item?.[0]).format('YYYY-MM-DDTHH:mm:ss'));
    const price = graphData?.map(item => item?.[1])
    console.log(price)

    const chartData = {
        series: [
            {
                name: name,
                data: price,
            },
        ],
        options: {
            chart: {
                height: 350,
                type: 'area',
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: 'smooth',
            },
            xaxis: {
                type: 'datetime',
                categories: date,
                labels: {
                    style: {
                        colors: '#94a3b8'
                    }
                }
            },
            yaxis: {
                decimalsInFloat: price?.[0] < 0 ? 6 : price?.[0] < 10 ? 4 : 0,
                labels: {
                    enabled: false,
                    style: {
                        colors: '#94a3b8'
                    }
                }
            },
            colors: ['#6C5DD3'],
            tooltip: {
                enabled: true,
            },
            legend: {
                enabled: false
            },
            toolbar: {
                enabled: false
            },
            markers: {
                size: 0,
                colors: undefined,
                strokeColors: '#6C5DD3',
                strokeWidth: 5,
                strokeOpacity: 0.9,
                strokeDashArray: 0,
                fillOpacity: 1,
                discrete: [],
                shape: "circle",
                radius: 6,
                offsetX: 0,
                offsetY: 0,
                onClick: undefined,
                onDblClick: undefined,
                showNullDataPoints: true,
                hover: {
                    size: undefined,
                    sizeOffset: 3
                }
            }
        },
    };

    return (
        <div id="chart">
            <ReactApexChart
                options={chartData.options}
                series={chartData.series}
                type="area"
                height={350}

            />
        </div>
    );
};

export default MyChart;