import React, { useEffect, useState } from 'react';
import logo from '../../assests/icons/Group.png'
import Logo from '../../assests/images/PayBack.png';
import dashboard from '../../assests/images/colorIcon.png';
import chart from '../../assests/images/Chart.png';
import pending from '../../assests/images/Combined-Shape.png';
import support from '../../assests/images/Wallet.png';
import avatarImage from '../../assests/icons/Oval.png';
import list from '../../assests/icons/list.png';
import Bag from '../../assests/images/Bag.png';
import transaction from '../../assests/icons/usertransaction.png';
import activeDashboard from '../../assests/icons/activedashboard.png';
import activeBuy from '../../assests/icons/activebag.png';
import activeChart from '../../assests/icons/activechart.png';
import activeTransaction from '../../assests/icons/activetransaction.png';
import activeSwap from '../../assests/icons/activewallet.png';
import activeSupport from '../../assests/icons/activeuser.png';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
import handleRefreshToken from '../../utils/RefreshToken'

const SideBar = ({ setShowSiderbar }) => {

    const location = useLocation();
    const pathname = location.pathname;

    const navItem = [
        {
            icon: dashboard,
            activeIcon: activeDashboard,
            title: 'Dashboard',
            url: '/admin/dashboard'
        },
        {
            icon: pending,
            activeIcon: activeSupport,
            title: 'User',
            url: 'managment'
        },
        {
            icon: chart,
            activeIcon: activeChart,
            title: 'Agency Management',
            url: 'agencymanagement'
        },
        {
            icon: pending,
            activeIcon: activeTransaction,
            title: 'Pending Transactions',
            url: 'transaction'
        },
        {
            icon: support,
            activeIcon: activeSwap,
            title: 'Support Ticket',
            url: 'support'
        }
    ]

    useEffect(() => {
        const time = Number(localStorage.getItem('time'));
        const interval = setInterval(() => {
            if (time + 40 * 60 * 1000 < Date.now()) {
                handleRefreshToken();
                localStorage.setItem('time', Date.now());
                localStorage.getItem('timestamp', Date.now() + 1000 * 60 * 60);
            }
        }, 5000)
        return () => clearInterval(interval);
    })

console.log(pathname.slice(0,6),"pathName-->");
    return (
        <div className='h-screen bg-theme-white dark:bg-dark overflow-y-auto scroll-smooth'>
            <div className='px-6 py-8'>
                <div className={`flex justify-center ${pathname.slice(0,6)=='/admin'?'':'h-28'}`}>
                    <img className={` ${pathname.slice(0,6)=='/admin'?'w-[80%]':'h-28'}   `} src={Logo} alt="logo" />
                    <BiArrowBack className='visible xl:hidden' onClick={() => setShowSiderbar(false)} size={24} />
                </div>
                <div className='my-10'>
                    {navItem.map(({ title, icon, url, activeIcon }, index) => {
                        return (
                            <NavLink onClick={() => setShowSiderbar(false)} className={`${pathname.includes(url) ? 'bg-hover dark:hover:bg-dark-active dark:bg-dark-active' : (pathname === '/admin' && index === 0) ? 'bg-hover dark:hover:bg-dark-active dark:bg-dark-active' : ''} hover:bg-hover dark:hover:bg-dark-hover flex h-12 m-2 items-center p-3 gap-3 rounded-xl transition-colors duration-300`
                            } to={url} key={index} >
                                <img src={pathname.includes(url) ? icon : icon} alt="icon" />
                                <p className=' text-[#6C5DD3] text-base font-primary font-semibold'>{title}</p>
                            </NavLink>
                        )
                    })}
                </div>
                <div className='w-full flex justify-center'>
                    <div className='w-full h-[1px] bg-line' />
                </div>
                {/* <Link to='/user'>
                    <div className='w-full h-12 items-center gap-4 flex px-5 my-4'>
                        <img className='w-6 h-4' src={arrow} alt="arrow" />
                        <p className='text-[#081735] text-base font-primary font-semibold'>Go Back</p>
                    </div>
                </Link>
                <div className='flex h-16 gap-3 items-center p-4 mt-14'>
                    <img className='w-12 h-12' src={avatarImage} alt="avatarImage" />
                    <div>
                        <p className='text-sm font-bold leading-4 font-primary'>Jane Eyre</p>
                        <p className='text-sm font-nunito text-[#6C5DD3]'>My Account</p>
                    </div>
                    <img className='w-7 h-7 ml-6' src={list} alt="list" />
                </div> */}
            </div>
        </div >
    )
}

export default SideBar;