import React, { useEffect, useState } from "react";
import trans_pic from '../../../assests/images/payment_image.png'
import { Button, Input, Modal, Pagination, Spin } from 'antd';
import { BiSearch, BiUserCircle } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { changeHeader } from '../../../redux/reducer/headerSlice'
import handleGet from "../../../utils/Get";
import { BASE_URL } from "../../../utils/config";
import image from '../../../assests/images/Arrow 2.png';
import eyeimage from '../../../assests/images/akar-icons_eye.png';
import TextArea from "antd/es/input/TextArea";
import { AiOutlineLink } from "react-icons/ai";
import { GoCopy } from "react-icons/go";
import handlePost from "../../../utils/Post";
import dayjs from "dayjs";
import { Link } from "react-router-dom";


const UserTransaction = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [transactions, setTransactions] = useState('');
  const [isModalOpen, setIsModalOpen] = useState({ open: false, data: '' });
  const [metaData, setMetaData] = useState('');
  const [note, setNote] = useState('');
  const [loading, setLoading] = useState({ approve: false, decline: false });
  const [userName, setUserName] = useState('');


  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const fetchTransactions = async () => {
    try {
      const data = await handleGet(`${BASE_URL}active/user/transaction?page_number=${currentPage}`);
      setTransactions(data?.transactions);
      setMetaData(data?.meta);
    }
    catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (isModalOpen?.open) {
      const fetchUser = async () => {
        const user = await handleGet(`${BASE_URL}get/user/detail/${isModalOpen?.data?.user_id}`)
        setUserName(user?.user?.email);
      }
      fetchUser();
    }
  }, [isModalOpen])

  useEffect(() => {
    dispatch(changeHeader(`${user.name}, Transactions`));
  }, [user])

  useEffect(() => {
    fetchTransactions();
  }, [currentPage])


  return (
    <section>
      <div className="">
        <div className="">
          <div className="w-full">
            <Input className='max-w-xs h-10 shadow-md rounded-xl' placeholder="Email Address" value="Filter transactions..." prefix={<BiSearch size={24} color='#9AA0AC' />} size="middle" />
          </div>
          <div className="w-full h-full overflow-x-auto p-2">
            {
              transactions ? transactions?.length ? Array.isArray(transactions) && transactions?.map(item => {
                return (
                  <div className="w-full h-full min-w-[480px]" key={item?.id}>
                    <div className="flex items-center h-24 shadow-md rounded-xl mt-4 px-4  dark:bg-dark dark:border dark:border-[#334155] bg-theme-white">
                      <div className="arrow me-3 p-3 border-[1px] border-gray-200 dark:border dark:border-[#334155]" style={{ boxShadow: '0px 12px 24px 0px #8F95B226', borderRadius: '10px' }}>
                        <img style={{ width: '17px' }} src={image} alt="Arrow" />
                      </div>
                      <div className="text-content">
                        <h2 className='font-nunito'><span className="text-[#000] dark:text-white">{item?.asset?.name}</span> <span className="text-gray-400">({item?.status})</span></h2>
                        <div className='flex gap-2'>
                          <p className='font-nunito text-gray-400'>{item?.amount}</p>
                          <p className='font-nunito text-gray-400'>({item?.type === 'withdraw' ? 'withdrawal' : item?.type})</p>
                        </div>
                      </div>
                      <div className="text-content" style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                        <div>
                          <p className='font-nunito text-gray-400 text-sm'>At: {item?.sent_at ? dayjs(item?.sent_at).format('DD-MM-YYYY hh:mm') : dayjs().format('DD-MM-YYYY hh:mm')}</p>
                        </div>
                        <div className='p-3'>
                          <img className="w-8 h-8" src={eyeimage} alt="eyeimage" onClick={() => setIsModalOpen({ open: true, data: item })} />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
                :
                <div className="flex flex-col items-center">
                  <img className="max-w-xl w-full" src={trans_pic} alt="trans_pic" />
                  <p className='mt-4 text-2xl dark:text-[#fff] font-nunito'>No Transactions Found</p>
                  <p className='dark:text-dark-text text-gray-500 font-nunito font-medium'>Try to add filter or add a new transaction</p>
                </div>
                :
                <div className='w-full h-48 flex justify-center items-end'><Spin size='large' /></div>
            }
            {transactions?.length ?
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                <Pagination
                  current={currentPage}
                  defaultCurrent={1}
                  total={metaData?.total}
                  pageSize={metaData?.per_page}
                  onChange={(page) => setCurrentPage(page)}
                />
              </div> : ''}
            <Modal
              width={620}
              centered={true}
              title=''
              open={isModalOpen?.open}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={false}
            >
              <div className="">
                <div className=''>
                  <div className='flex flex-wrap items-center gap-4'>
                    <div>
                      <h1 className='font-nunito text-xl min-[560px]:text-3xl font-bold'>Transaction Details</h1>
                      <div className='flex items-center'>
                        <BiUserCircle size={24} />
                        <h1 className='text-center font-bold'>User: {userName ? userName : '---'}</h1>
                      </div>
                    </div>
                    <Link to='/user/profile'>
                      <Button size='large' className='flex items-center'>
                        <AiOutlineLink size={20} className='me-2' /> User Profile
                      </Button>
                    </Link>
                  </div>
                </div>
                <div className='grid grid-cols-3 items-center font-nunito my-2 mt-4'>
                  <div className='col-span-3 min-[560px]:col-span-2'>
                    <h1 className='text-gray-600'>Transaction ID</h1>
                    <div className='flex items-center gap-2'>
                      <p className='font-bold text-nowrap text-[10px] sm:text-xs md:text-sm'>{isModalOpen?.data?.uuid}</p>
                      <GoCopy size={20} className='' />
                    </div>
                  </div>
                  <div className='col-span-3 min-[560px]:col-span-1 mt-2 min-[560px]:mt-0'>
                    <h1 className='text-gray-600'>Transaction Hash</h1>
                    <div className='flex gap-2 items-center'>
                      <p className='font-bold'>N/A</p>
                      <GoCopy size={20} className='' />
                    </div>
                  </div>
                </div>
                <div className='grid grid-cols-3 my-2 items-center'>
                  <div className='col-span-2'>
                    <h1 className='text-gray-600'>TimeStamp</h1>
                    <div className='flex items-center'>
                      <p className='font-bold'>{isModalOpen?.data?.sent_at ? dayjs(isModalOpen?.data?.sent_at).format('DD-MM-YYYY hh:mm:ss') : dayjs().format('DD-MM-YYYY hh:mm:ss')}</p>
                    </div>
                  </div>
                  <div className='col-span-1'>
                    <h1 className='text-gray-600'>Block</h1>
                    <div className='flex items-center'>
                      <p className='font-bold'>N/A</p>
                    </div>
                  </div>
                </div>
                <div className='grid grid-cols-3 items-center my-2'>
                  <div className='col-span-2'>
                    <h1 className='text-gray-600'>Value</h1>
                    <div className='flex items-center gap-2'>
                      <p className='font-bold'>{isModalOpen?.data?.amount ?? 'N/A'}</p>
                      <GoCopy size={20} />
                    </div>
                  </div>
                  <div className='col-span-1'>
                    <h1 className='text-gray-600'>Status</h1>
                    <div className='flex items-center gap-2'>
                      <p className={`${"pending" == "pending" ? "text-[#7EBA00]" : "text-red-400"} font-bold capitalize`}>{isModalOpen?.data?.status}</p>
                    </div>
                  </div>

                </div>
                <div className='grid grid-cols-3 items-center my-2'>
                  <div className='col-span-3'>
                    <h1 className='text-gray-600'>To</h1>
                    <div className='flex items-center gap-2'>
                      <p className='font-bold text-xs sm:text-sm text-nowrap'>{isModalOpen?.data?.to_address ?? 'N/A'}</p>
                      <GoCopy size={20} />
                    </div>
                  </div>
                  <div className='col-span-3 my-2'>
                    <h1 className='text-gray-600'>From</h1>
                    <div className='flex items-center gap-2'>
                      <p className='font-bold text-xs sm:text-sm text-nowrap'>{isModalOpen?.data?.from_address ?? 'N/A'}</p>
                      <GoCopy size={20} />
                    </div>
                  </div>
                </div>
              </div>

            </Modal>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserTransaction;
