import React, { useEffect, useState } from 'react';
import styles from '../auth.module.css'
import LoginImage from '../../assests/icons/Group.png';
import Logo from '../../assests/icons/Group.png';
import Logo_1 from '../../assests/images/PayBack.png';
import { Checkbox, Divider, Input, notification } from 'antd';
import { IoMailOutline } from "react-icons/io5";
import { IoLockClosedOutline } from "react-icons/io5";
import Google_logo from '../../assests/images/google.png'
import LinkedIn_logo from '../../assests/images/linkedIn.png'
import Twitter_logo from '../../assests/images/teitter.png'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../utils/config';
import bitcoin from '../../assests/icons/bit.jpg'
import login from '../../assests/images/login.png'
import OTPInput from 'react-otp-input';



export default function Login() {
    const [userData, setUserData] = useState({ email: '', password: '', reset_code: '' });
    const [loading, setLoading] = useState(false)
    const [step, setStep] = useState({ first: true, second: false, third: false })
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const userDataLocal = localStorage.getItem('user');
    const timeStamp = localStorage.getItem('timestamp');
    // const user = JSON.parse(userDataLocal);

    const handleForgotEmail = async () => {
        if (!loading) {
            setLoading(true);
            try {
                const response = await axios.post(`${BASE_URL}send-reset-code`, { email: userData?.email });
                if (response) {
                    setStep({ second: true });
                    notification.success({ message: response?.data?.message })
                    setLoading(false)
                }
                console.log(response)
            }
            catch (error) {
                setLoading(false)
            }
        }
    }
    const handleForgot = async () => {
        if (!loading) {
            setLoading(true);
            try {
                const response = await axios.post(`${BASE_URL}reset-password`, { ...userData });
                if (response) {
                    notification.success({ message: response?.data?.message })
                    navigate('/login');
                    setLoading(false);
                }
                console.log(response)
            }
            catch (error) {
                setLoading(false)
            }
        }
    }


    return (!token &&
        <div className="w-full h-[100vh] overflow-hidden dark:bg-dark">
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
                <div className="hidden lg:flex col-span-1 xl:col-span-2">
                    <img className='w-full h-[100vh] object-fill' src='/image.jpeg' alt={bitcoin} />
                </div>
                <div className='col-span-1 h-[100vh] p-7 md:p-9 overflow-x-hidden overflow-y-auto flex flex-col items-center'>
                    <div className='max-w-md'>
                        <div className="row pt-2">
                            <div className="col-md-12">
                                <div className='flex items-center gap-4 pt-2 mb-2 '>
                                    <img className='h-[70px]' src={Logo_1} alt={Logo} />
                                </div>
                            </div>
                        </div>
                        <div className="row pb-2">
                            <div className="col-md-12">
                                <h3 className='text-3xl font-bold font-nunito dark:text-white'>Hi, Welcome Back!</h3>
                            </div>
                            <div className="col-md-12">
                                <span className='text-[#8F95B2] font-nunito text-base dark:text-dark-text'>Login with social media or your credientials</span>
                            </div>
                        </div>
                        <div className="w-full flex gap-2 flex-wrap">
                            <div className="flex items-center gap-2 border rounded py-2 px-4 w-fit h-fit">
                                <img src={Google_logo} alt="" />
                            </div>
                            <div className='border rounded flex items-center justify-center w-fit py-2 px-4'>
                                <img src={LinkedIn_logo} alt="" />
                            </div>
                            <div className='border rounded flex items-center justify-center w-fit py-2 px-4'>
                                <img src={Twitter_logo} alt="" />
                            </div>
                        </div>
                        <Divider plain>Or sign in with email</Divider>
                        <div className="row py-2">
                            {step?.first ? <div className="col-md-12 py-1">
                                <span className='font-nunito dark:text-dark-text'>Email address</span>
                                <Input value={userData?.email} onKeyDown={(e) => e.key === 'Enter' && handleForgotEmail()} placeholder="Email Address" onChange={(e) => setUserData({ ...userData, email: e.target.value })} prefix={<IoMailOutline />} size="large" />
                            </div> :
                                step?.second ?
                                    <div className="col-md-12 py-1">
                                        <OTPInput
                                            value={userData?.reset_code}
                                            containerStyle={{
                                                margin: "auto",
                                                marginBottom: "20px",
                                                marginTop: 32,
                                                justifyContent: "center",
                                            }}
                                            onChange={e => setUserData({ ...userData, reset_code: e })}
                                            inputStyle={{
                                                width: "48px",
                                                padding: "12px",
                                                border: "1px solid #ACACAC",
                                                borderRadius: 12
                                            }}
                                            numInputs={6}
                                            renderSeparator={
                                                <span style={{ marginInline: "5px" }}>  </span>
                                            }
                                            renderInput={(props) =>
                                                <input {...props} />}
                                        />
                                    </div> :
                                    step?.third &&
                                    <div className="col-md-12 py-1">
                                        <span className='font-nunito dark:text-dark-text'>Enter New Password</span>
                                        <Input.Password value={userData?.password} onKeyDown={(e) => e.key === 'Enter' && handleForgot()} placeholder="Enter new password" onChange={(e) => setUserData({ ...userData, password: e.target.value })} prefix={<IoLockClosedOutline />} size="large" />
                                    </div>
                            }
                        </div>
                        <div className="row my-4">
                            <div className="col-md-12">
                                {step?.first ? <button disabled={loading} onClick={handleForgotEmail} className={`${styles.LoginButton} ${loading ? 'opacity-70' : ''}`}>Get Code</button>
                                    : step?.second ?
                                        <button disabled={loading} onClick={() => userData?.reset_code ? setStep({ third: true }) : notification.error({ message: 'Enter Code' })} className={`${styles.LoginButton} ${loading ? 'opacity-70' : ''}`}>Send Code</button>
                                        : step?.third &&
                                        <button disabled={loading} onClick={handleForgot} className={`${styles.LoginButton} ${loading ? 'opacity-70' : ''}`}>Reset Password</button>
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <span className='dark:text-white'>Already have account?<Link to='/login' style={{ color: '#8F95B2', fontWeight: '600', borderBottom: '1px solid #8F95B2', marginLeft: '5px', cursor: 'pointer' }}>Go to login</Link></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
