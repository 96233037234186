import React, { useEffect, useState } from 'react';
import styles from '../auth.module.css'
import LoginImage from '../../assests/icons/Group.png';
import Logo from '../../assests/icons/Group.png';
import Logo_1 from '../../assests/images/PayBack.png';
import { Checkbox, Divider, Input, notification } from 'antd';
import { IoMailOutline } from "react-icons/io5";
import { IoLockClosedOutline } from "react-icons/io5";
import Google_logo from '../../assests/images/google.png'
import LinkedIn_logo from '../../assests/images/linkedIn.png'
import Twitter_logo from '../../assests/images/teitter.png'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../utils/config';
import bitcoin from '../../assests/icons/bit.jpg'
import Bitimage from '../../assests/images/image.jpeg'



export default function Login() {
    const [userData, setUserData] = useState({ email: '', password: '' });
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const userDataLocal = localStorage.getItem('user');
    const timeStamp = localStorage.getItem('timestamp');
    const user = JSON.parse(userDataLocal);

    const handleLogin = async () => {
        if (!loading) {
            setLoading(true);
            try {
                const response = await axios.post(`${BASE_URL}login`, { ...userData });
                if (response?.data) {
                    const token = response?.data?.authorization?.token;
                    const user = response?.data?.user;
                    localStorage.setItem('token', token);
                    localStorage.setItem('user', JSON.stringify(user));
                    localStorage.setItem('timestamp', Date.now() + 3600 * 1000);
                    localStorage.setItem('time', Date.now());
                    notification.success({
                        message: 'Login successful'
                    })
                    if (user.type === 'admin' || user?.type === 'agent') {
                        navigate('/admin')
                    }
                    else {
                        navigate('/user')
                    }
                    return setUserData({ email: '', password: '' })
                }
            }
            catch (error) {
                setLoading(false)
                console.log(error)
                if (error?.response && error.response.data && error.response.data.errors) {
                    const errors = error.response.data.errors;
                    if (errors?.email) {
                        notification.error({
                            message: errors?.email[0]
                        })
                        return;
                    }
                    else if (errors?.password) {
                        notification.error({
                            message: errors?.password[0]
                        })
                        return;
                    }
                }
                else if (error?.response?.data?.message === 'User Banned') {
                    notification.error({ message: error?.response?.data?.message })
                }
                else {
                    notification.error({
                        message: error?.response?.data?.message,
                    });
                }
            }
        }
    }

    useEffect(() => {
        if (timeStamp < Date.now()) {
            localStorage.removeItem('token')
            localStorage.removeItem('timestamp')
            const user = JSON.parse(localStorage.getItem('user'));
            setUserData({ email: user?.email, password: '' })
            return navigate('/login');
        }
        if (token && user) {
            if (user?.type === 'admin' || user?.type === 'agent') {
                navigate('/admin');
            }
            else if (user?.type === 'user') {
                navigate('/user')
            }

        }

    }, [])

    return (!token &&
        <div className="w-full h-[100vh] overflow-hidden dark:bg-dark">
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
                <div className="hidden lg:flex col-span-1 xl:col-span-2">
                    <img className='w-full h-[100vh] object-fill' src={Bitimage} alt={'Login Image'} />
                </div>
                <div className='col-span-1 h-[100vh] p-7 md:p-9 overflow-x-hidden overflow-y-auto flex flex-col items-center'>
                    <div className='max-w-md'>
                        <div className="row pt-2">
                            <div className="col-md-12">
                                <div className='flex items-center gap-4 pt-2 mb-2 '>
                                    <img src={Logo_1} alt={Logo} style={{ height: "120px" }} />
                                    {/* <span className='text-[#6C5DD3] text-3xl font-nunito font-semibold'>LOGO HERE</span> */}
                                </div>
                            </div>
                        </div>
                        <div className="row pb-2">
                            <div className="col-md-12">
                                <h3 className='text-3xl font-bold font-nunito dark:text-white'>Hi, Welcome Back!</h3>
                            </div>
                            <div className="col-md-12">
                                <span className='text-[#8F95B2] font-nunito text-base dark:text-dark-text'>Login with social media or your credientials</span>
                            </div>
                        </div>
                        <div className="w-full flex gap-2 flex-wrap">
                            <div className="flex items-center gap-2 border rounded py-2 px-4 w-fit h-fit">
                                <img src={Google_logo} alt="" />
                                {/* <span className='font-nunito text-base'> Sign in with Google</span> */}
                            </div>
                            <div className='border rounded flex items-center justify-center w-fit py-2 px-4'>
                                <img src={LinkedIn_logo} alt="" />
                            </div>
                            <div className='border rounded flex items-center justify-center w-fit py-2 px-4'>
                                <img src={Twitter_logo} alt="" />
                            </div>
                        </div>
                        <Divider plain>Or sign in with email</Divider>
                        <div className="row py-2">
                            <div className="col-md-12 py-1">
                                <span className='font-nunito dark:text-dark-text'>Email address</span>
                                <Input value={userData?.email} onKeyDown={(e) => e.key === 'Enter' && handleLogin()} placeholder="Email Address" onChange={(e) => setUserData({ ...userData, email: e.target.value })} prefix={<IoMailOutline />} size="large" />
                            </div>
                            <div className="col-md-12">
                                <span className='font-nunito dark:text-dark-text'>Password</span>
                                <Input.Password value={userData?.password} onKeyDown={(e) => e.key === 'Enter' && handleLogin()} placeholder="input password" onChange={(e) => setUserData({ ...userData, password: e.target.value })} prefix={<IoLockClosedOutline />} size="large" />
                            </div>
                        </div>
                        <div className="row py-3">
                            <div className="col-md-6">
                                <Checkbox defaultChecked={true} >Remember me?</Checkbox>
                            </div>
                            <div className="col-md-6 text-end">
                                <Link to='/forgot-password'><span className='dark:text-dark-text'>Forgot your password?</span></Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <button disabled={loading} onClick={handleLogin} className={`${styles.LoginButton} ${loading ? 'opacity-70' : ''}`}>Sign in</button>
                            </div>
                        </div>
                        <div className="row py-4">
                            <div className="col-md-12">
                                <span className='dark:text-white'>Not registered yet?<Link to='/signup' style={{ color: '#8F95B2', fontWeight: '600', borderBottom: '1px solid #8F95B2', marginLeft: '5px', cursor: 'pointer' }}>Create an account</Link></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
