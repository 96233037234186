import React, { useEffect, useState } from 'react';
import { BiSolidUser } from 'react-icons/bi';
import { HiOutlineArrowDownRight, HiArrowUpLeft } from 'react-icons/hi2';
import { AiFillLock } from 'react-icons/ai';
import { PiArrowsClockwiseBold } from 'react-icons/pi'
import { CiMobile3 } from 'react-icons/ci';
import { CiMail } from 'react-icons/ci';
import { GoLocation } from 'react-icons/go';
import { BiSolidCity } from 'react-icons/bi';
import { BsHash } from 'react-icons/bs';
import { GoRocket } from 'react-icons/go';
import { FaBan } from 'react-icons/fa';
import { PiWalletBold } from 'react-icons/pi';
import { AiFillSetting } from 'react-icons/ai'
import { RxCross2 } from 'react-icons/rx'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
import { AiFillSave } from 'react-icons/ai'
import { Button, DatePicker, Dropdown, Input, InputNumber, Modal, Spin, Switch } from 'antd';
import image from '../../../assests/images/Arrow 2.png';
import eyeimage from '../../../assests/icons/Frame 58.png';
import eyeimage2 from '../../../assests/images/akar-icons_eye.png';
import secure from '../../../assests/icons/ri_secure-payment-fill.png'
import { useParams } from 'react-router';
import handleGet from '../../../utils/Get';
import { BASE_URL } from '../../../utils/config'
import handlePost from '../../../utils/Post';
import dayjs from 'dayjs';
import FileSaver from 'file-saver';
import { HiDocumentDownload } from 'react-icons/hi'
import axios from 'axios';

const { TextArea } = Input;

const Usermanagementsettings = () => {

    const [tabManage, setTabManage] = useState({ first: true, second: false, third: false, fourth: false });
    const [data, setData] = useState('')
    const [loading, setLoading] = useState({ own: false, ban: false, user: false, update: false, withdraw: false, deposit: false });
    const [assets, setAssets] = useState('');
    const [transactions, setTransactions] = useState('');
    const [depositModal, setDeposiitModal] = useState({ open: false, data: '' });
    const [withdrawModal, setWithdrawModal] = useState({ open: false, data: '' });
    const [updateModal, setUpdateModal] = useState({ open: false, data: '' });
    const [updateAdddress, setUpdateAdddress] = useState('');
    const [withdrawData, setWithdrawData] = useState({ amount: '', send_at: '', hidden: false, tx_id: '', to_address: '', note: '', type: '', user_id: '', asset_id: '' })
    const [depositData, setDepositData] = useState({ amount: '', send_at: '', hidden: false, tx_id: '', from_address: '', note: '', type: '', user_id: '', asset_id: '' })
    const { id } = useParams();
    const [status, setStatus] = useState('');
    const regex = /^\d+$/


    const fetchData = async () => {
        try {
            const data = await handleGet(`${BASE_URL}get/user/assets/${id}`);
            setAssets(data?.assets)
        }
        catch (error) {
            console.log(error.message);
        }
    }

    const fetchUser = async () => {
        const data = await handleGet(`${BASE_URL}get/user/detail/${id}`)
        if (data) {
            setData(data?.user)
        }
    }

    useEffect(() => {
        fetchData();
        fetchUser();
    }, [id]);

    useEffect(() => {
        const fetchTransactions = async () => {
            const data = await handleGet(`${BASE_URL}transaction/get/all?page_number=1`);
            setTransactions(data?.transactions);
        };
        fetchTransactions();
    }, [])

    const handleUpdateUser = async () => {
        setLoading({ ...loading, user: true });
        try {
            const formData = {
                'name': data.name,
                'email': data.email,
                'details[phone_number]': data?.detail?.phone_number,
                'details[address]': data?.detail?.address,
                'details[city]': data?.detail?.city,
                'details[country]': data?.detail?.country,
                'details[postal_code]': data?.detail?.postal_code,
                'details[note]': data?.detail?.note,
            }
            const response = await handlePost(`${BASE_URL}update/user/${id}`, formData)
            setLoading({ ...loading, user: false });
        }
        catch (error) {
            console.log(error)
            setLoading({ ...loading, user: false });
        }
    }

    const handleUserStatus = async (data) => {
        setLoading({ ...loading, ban: true })
        try {
            const response = await handlePost(`${BASE_URL}user/approve/kyc/${id}`, { 'status': data })
            setLoading({ ...loading, ban: false });
            fetchUser();
        }
        catch (err) {
            console.log(err)
        }
    }

    const handleUserBan = async (data) => {
        setLoading({ ...loading, ban: true })
        try {
            const response = await handlePost(`${BASE_URL}user/update/status/${id}`, { 'status': data })
            setLoading({ ...loading, ban: false });
        }
        catch (err) {
            console.log(err)
        }
    }

    const handleCloseDeposit = () => {
        setDeposiitModal(false)
    }

    const handleCloseWithdraw = () => {
        setWithdrawModal(false)
    }

    const handleCloseUpdate = () => {
        setUpdateModal(false)
    }

    const handleUpdateAssets = async () => {
        setLoading({ ...loading, update: true })
        try {
            const data = await handlePost(`${BASE_URL}update/asset/address`, { 'user_id': id, 'address': updateAdddress, 'asset_id': updateModal?.data?.id })
            setUpdateModal(false);
            console.log(data)
            setLoading({ ...loading, update: false })
            fetchData();
        }
        catch (err) {
            console.log(err)
            setLoading({ ...loading, update: false })
        }
    }

    const handleWithdrawAssets = async () => {
        setLoading({ ...loading, withdraw: true });
        try {
            const response = await handlePost(`${BASE_URL}transaction/make/withdraw`, { ...withdrawData, hidden: withdrawData?.data ? 1 : 0, asset_id: withdrawModal?.data?.id, user_id: id, type: 'withdraw', send_at: withdrawData?.send_at ? dayjs(withdrawData?.send_at).format('DD/MM/YYYY hh:mm:ss') : dayjs().format('DD/MM/YYYY hh:mm:ss') });
            setLoading({ ...loading, withdraw: false });
            if (response) {
                setWithdrawData('');
                setWithdrawModal(false);
            }
        }
        catch (error) {
            console.log(error);
            setLoading({ ...loading, withdraw: false });
        }

    }

    const handleDepositAsset = async () => {
        setLoading({ ...loading, deposit: true });
        try {
            const response = await handlePost(`${BASE_URL}transaction/make/deposit`, { ...depositData, hidden: depositData?.hidden ? 1 : 0, asset_id: depositModal?.data?.id, user_id: id, type: 'deposit', send_at: depositData?.send_at ? dayjs(depositData?.send_at).format('DD/MM/YYYY hh:mm:ss') : dayjs().format('DD/MM/YYYY hh:mm:ss') });
            setLoading({ ...loading, deposit: false });
            if (response) {
                setDepositData('');
                setDeposiitModal(false);
            }
        } catch (error) {
            console.log(error)
            setLoading({ ...loading, deposit: false });
        }

    }

    const preventDecimal = (value) => {
        // Use parseInt to convert the value to an integer, effectively removing any decimal part
        return parseInt(value, 10);
    };



    return (data ?
        <div className='grid grid-cols-10'>
            <Modal
                width={620}
                closeIcon={false}
                open={depositModal?.open}
                footer={() => (
                    <div>
                        <Button size='large' className='px-6 font-nunito' onClick={handleCloseDeposit}>Cancel</Button>
                        <Button loading={loading?.deposit} size='large' className='px-6 font-nunito bg-[#6C5DD3] text-white' onClick={handleDepositAsset}>Create</Button>
                    </div>
                )}
            >
                <div className='flex justify-between items-center'>
                    <p className='font-nunito text-xl sm:text-2xl dark:text-dark-text font-semibold'>Create new Deposit</p>
                    <RxCross2 onClick={handleCloseDeposit} size={24} />
                </div>
                <div className='my-4'>
                    <div className='flex gap-2 items-center my-4'>
                        <p className='font-nunito text-lg text-nowrap'>Selected Currency:</p>
                        <Button className='bg-[#6C5DD3] text-white font-nunito' type='text'>{depositModal?.data?.name}</Button>
                    </div>
                    <div>
                        <label className='block font-nunito dark:text-dark-text text-gray-500 font-medium'>Amount</label>
                        <InputNumber
                            min={0}
                            type='number'
                            step={1}
                            value={depositData?.amount}
                            onChange={e => setDepositData({ ...depositData, amount: e })}
                            className='w-full'
                            size='large'
                            placeholder='0.00000'
                            keyboard={false}
                        />
                    </div>
                    <div className='my-4'>
                        <label className='block font-nunito dark:text-dark-text text-gray-500 font-medium'>Send At</label>
                        <DatePicker value={withdrawData.send_at ? dayjs(withdrawData.send_at, 'DD/MM/YYYY hh:mm:A') : dayjs()} onChange={(date, value) => setDepositData({ ...depositData, send_at: value })} className='w-full' size='large' allowClear={false} format={'DD/MM/YYYY hh:mm:A'} />
                    </div>
                    <p className='font-nunito text-xl font-bold dark:text-dark-text text-[#000]'>Transaction details</p>
                    <p className='text-gray-500 dark:text-dark-text font-nunito text-sm leading-4'>Add some transaction details</p>
                    <div className='flex items-center gap-4 my-4'>
                        <p className='font-nunito font-bold dark:text-dark-text text-gray-500'>Show Transaction?</p>
                        <div className='flex items-center gap-2'>
                            <Switch className='bg-[#bdbdbd]' onChange={e => setDepositData({ ...depositData, hidden: e })} checked={depositData?.hidden} />
                            <div>
                                <p className='font-nunito font-bold'>Is Hidden</p>
                                <p className='font-nunito leading-3 text-sm dark:text-dark-text text-gray-500'>Show user this transaction</p>
                            </div>
                        </div>
                    </div>
                    <div className='grid grid-cols-4 items-center'>
                        <label className='col-span-1 font-nunito dark:text-dark-text text-gray-500 font-medium'>Tx ID</label>
                        <div className='col-span-3'>
                            <Input onChange={e => setDepositData({ ...depositData, tx_id: e.target.value })} value={depositData?.tx_id} placeholder='Ex:0.00000' size='large' className='w-full' prefix={<BsHash />} />
                        </div>
                    </div>
                    <div className='grid grid-cols-4 items-center my-4'>
                        <label className='col-span-1 font-nunito dark:text-dark-text text-gray-500 font-medium'>From Address</label>
                        <div className='col-span-3'>
                            <Input value={depositData?.from_address} onChange={e => setDepositData({ ...depositData, from_address: e.target.value })} placeholder='Ex:Address' size='large' className='w-full' prefix={<BsHash />} />
                        </div>
                    </div>
                    <div className='grid grid-cols-4 items-center'>
                        <label className='col-span-1 font-nunito dark:text-dark-text text-gray-500 font-medium'>Status</label>
                        <div className='col-span-3'>
                            <Input disabled value={'Pending'} placeholder='Ex:Address' size='large' className='w-full dark:bg-[#141f38] dark:text-dark-text' suffix={<MdOutlineKeyboardArrowDown />} />
                        </div>
                    </div>
                    <div className='grid grid-cols-4 items-start my-4'>
                        <label className='col-span-1 font-nunito dark:text-dark-text text-gray-500 font-medium'>Note</label>
                        <div className='col-span-3'>
                            <TextArea onChange={e => setDepositData({ ...depositData, note: e.target.value })} value={depositData?.note} rows={4} />
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                width={620}
                closeIcon={false}
                open={withdrawModal?.open}
                footer={() => (
                    <div>
                        <Button size='large' className='px-6 font-nunito' onClick={handleCloseWithdraw}>Cancel</Button>
                        <Button loading={loading?.withdraw} size='large' className='px-6 font-nunito bg-[#6C5DD3] text-white' onClick={handleWithdrawAssets}>Create</Button>
                    </div>
                )}
            >
                <div className='flex justify-between items-center'>
                    <p className='font-nunito text-xl sm:text-2xl dark:text-dark-text font-semibold'>Create new Withdraw</p>
                    <RxCross2 onClick={handleCloseWithdraw} size={24} />
                </div>
                <div className='my-4'>
                    <div className='flex gap-2 items-center my-4'>
                        <p className='font-nunito text-lg dark:text-dark-text text-nowrap'>Selected Currency:</p>
                        <Button className='bg-[#6C5DD3] text-white font-nunito' type='text'>{withdrawModal?.data?.name}</Button>
                    </div>
                    <div>
                        <label className='block font-nunito dark:text-dark-text text-gray-500 font-medium'>Amount</label>
                        <InputNumber step={1} keyboard={false} min={0} type='number' value={withdrawData?.amount} onChange={value => setWithdrawData({ ...withdrawData, amount: value })} name='amount' className='w-full' size='large' placeholder='0.00000' />
                    </div>
                    <div className='my-4'>
                        <label className='block font-nunito dark:text-dark-text text-gray-500 font-medium'>Send At</label>
                        <DatePicker value={withdrawData.send_at ? dayjs(withdrawData.send_at, 'DD/MM/YYYY hh:mm:A') : dayjs()} onChange={(date, value) => setWithdrawData({ ...withdrawData, send_at: value })} name='send_at' className='w-full' size='large' allowClear={false} format={'DD/MM/YYYY hh:mm:A'} />
                    </div>
                    <p className='font-nunito text-xl font-bold dark:text-dark-text text-[#000]'>Transaction details</p>
                    <p className='text-gray-500 font-nunito dark:text-dark-text text-sm leading-4'>Add some transaction details</p>
                    <div className='flex items-center gap-4 my-4'>
                        <p className='font-nunito font-bold dark:text-dark-text text-gray-500'>Show Transaction?</p>
                        <div className='flex items-center gap-2'>
                            <Switch className='bg-[#bdbdbd]' checked={withdrawData?.hidden} onChange={e => setWithdrawData({ ...withdrawData, hidden: e })} />
                            <div>
                                <p className='font-nunito font-bold dark:text-dark-text'>Is Hidden</p>
                                <p className='font-nunito leading-3 text-sm dark:text-dark-text text-gray-500'>Show user this transaction</p>
                            </div>
                        </div>
                    </div>
                    <div className='grid grid-cols-4 items-center'>
                        <label className='col-span-1 font-nunito dark:text-dark-text text-gray-500 font-medium'>Tx ID</label>
                        <div className='col-span-3'>
                            <Input value={withdrawData?.tx_id} onChange={e => setWithdrawData({ ...withdrawData, tx_id: e.target.value })} name='tx_id' placeholder='Ex:0.00000' size='large' className='w-full' prefix={<BsHash />} />
                        </div>
                    </div>
                    <div className='grid grid-cols-4 items-center my-4'>
                        <label className='col-span-1 font-nunito dark:text-dark-text text-gray-500 font-medium'>To Address</label>
                        <div className='col-span-3'>
                            <Input onChange={e => setWithdrawData({ ...withdrawData, to_address: e.target.value })} value={withdrawData?.to_address} name='from_address' placeholder='Ex:Address' size='large' className='w-full' prefix={<BsHash />} />
                        </div>
                    </div>
                    <div className='grid grid-cols-4 items-center'>
                        <label className='col-span-1 font-nunito dark:text-dark-text text-gray-500 font-medium'>Status</label>
                        <div className='col-span-3'>
                            <Input disabled placeholder='Ex:Address' value='Pending' size='large' className='w-full dark:bg-[#141f38] dark:text-dark-text' suffix={<MdOutlineKeyboardArrowDown />} />
                        </div>
                    </div>
                    <div className='grid grid-cols-4 items-start my-4'>
                        <label className='col-span-1 font-nunito dark:text-dark-text text-gray-500 font-medium'>Note</label>
                        <div className='col-span-3'>
                            <TextArea value={withdrawData?.note} onChange={e => setWithdrawData({ ...withdrawData, note: e.target.value })} name='note' rows={4} />
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                width={620}
                closeIcon={false}
                open={updateModal?.open}
                footer={(_) => (
                    <div>
                        <Button size='large' className='px-6 font-nunito' onClick={handleCloseUpdate}>Cancel</Button>
                        <Button loading={loading?.update} size='large' className='px-6 font-nunito bg-[#6C5DD3] text-white' onClick={handleUpdateAssets}>Update</Button>
                    </div>
                )}
            >
                <div className='flex justify-between items-center'>
                    <p className='font-nunito text-2xl dark:text-dark-text font-semibold'>Update Asset</p>
                    <RxCross2 onClick={handleCloseUpdate} size={24} />
                </div>
                <div className='my-4'>
                    <div className='flex gap-2 items-center my-4'>
                        <p className='font-nunito text-lg text-nowrap dark:text-dark-text'>Selected Currency:</p>
                        <Button className='bg-[#6C5DD3] text-white font-nunito' type='text'>{updateModal?.data?.name}</Button>
                    </div>
                    <div>
                        <label className='block font-nunito dark:text-dark-text text-gray-500 font-medium'>Address</label>
                        <Input value={updateAdddress} onChange={e => setUpdateAdddress(e.target.value)} className='w-full' size='large' placeholder='Ex:1fjsd357czcnj' />
                    </div>
                </div>
            </Modal>
            <div className={`col-span-10 ${tabManage.first || tabManage.fourth ? 'lg:col-span-4' : 'lg:col-span-3'}`}>
                <div onClick={() => setTabManage({ first: true, second: false, third: false, fourth: false })} className={`${tabManage.first ? 'bg-[#6C5DD3]' : ''} w-44 h-10 flex items-center gap-2 px-3 rounded-md`}>
                    <p className={`${tabManage.first ? 'text-white' : 'text-[#6B6B6B] dark:text-dark-text'} font-primary text-lg`}><BiSolidUser /></p>
                    <p className={`${tabManage.first ? 'text-white' : 'text-[#6B6B6B] dark:text-dark-text'} font-primary font-medium text-base`}>General</p>
                </div>
                <div onClick={() => setTabManage({ first: false, second: true, third: false, fourth: false })} className={`${tabManage.second ? 'bg-[#6C5DD3]' : ''} w-44 h-10 flex items-center gap-2 px-3 rounded-md`}>
                    <p className={`${tabManage.second ? 'text-white' : 'text-[#6B6B6B] dark:text-dark-text'} font-primary text-lg`}><AiFillLock /></p>
                    <p className={`${tabManage.second ? 'text-white' : 'text-[#6B6B6B] dark:text-dark-text'} font-primary font-medium text-base`}>Manage Assets</p>
                </div>
                <div onClick={() => setTabManage({ first: false, second: false, third: true, fourth: false })} className={`${tabManage.third ? 'bg-[#6C5DD3]' : ''} w-52 h-10 flex items-center gap-2 px-3 rounded-md`}>
                    <p className={`${tabManage.third ? 'text-white' : 'text-[#6B6B6B] dark:text-dark-text'} font-primary text-lg`}><PiWalletBold /></p>
                    <p className={`${tabManage.third ? 'text-white' : 'text-[#6B6B6B] dark:text-dark-text'} font-primary font-medium text-base`}>Manage Transactions</p>
                </div>
                <div onClick={() => setTabManage({ first: false, second: false, third: false, fourth: true })} className={`${tabManage.fourth ? 'bg-[#6C5DD3]' : ''} w-52 h-10 flex items-center gap-2 px-3 rounded-md`}>
                    <p className={`${tabManage.fourth ? 'text-white' : 'text-[#6B6B6B] dark:text-dark-text'} font-primary text-lg`}><AiFillSetting /></p>
                    <p className={`${tabManage.fourth ? 'text-white' : 'text-[#6B6B6B] dark:text-dark-text'} font-primary font-medium text-base`}>Manage Verification</p>
                </div>
            </div>
            <div className={`col-span-10 ${tabManage.first || tabManage.fourth ? 'lg:col-span-6' : 'lg:col-span-7'} dark:bg-dark dark:border dark:border-[#334155] bg-theme-white rounded-xl p-4 pb-0 mt-6 lg:mt-0`}>
                {
                    tabManage.first ?
                        <div className='flex flex-col items-center mb-4'>
                            <div className='w-full h-full'>
                                <div className='flex justify-between'>
                                    <div>
                                        <h2 className='text-2xl font-nunito font-bold dark:text-white'>Settings</h2>
                                    </div>
                                    <Button loading={loading?.user} onClick={handleUpdateUser} className='w-24 h-10 flex justify-center items-center gap-2 rounded-lg font-nunito text-white bg-[#7EBA00]'><AiFillSave size={20} /><p>Save</p></Button>
                                </div>
                                <p className='text-[#6B6B6B] dark:text-dark-text font-nunito text-base font-medium leading-3'>Edit user settings</p>
                            </div>
                            <div className="w-full my-3">
                                <span className='font-nunito text-[#6B6B6B] dark:text-dark-text'>Email address</span>
                                <Input name='email' onChange={e => setData({ ...data, email: e.target.value })} value={data?.email} className='mt-1' style={{ padding: '10px' }} placeholder="jdgrant07@hotmail.com" prefix={<CiMail />} size="large" />
                            </div>
                            <div className='flex mt-3' style={{ gap: '25px' }}>
                                <div>
                                    <span className='font-nunito text-[#6B6B6B] dark:text-dark-text' style={{ marginRight: '10px', display: 'inline-block' }}>First Name</span>
                                    <Input onChange={e => setData({ ...data, name: e.target.value })} placeholder="John" value={data?.name} size="large" style={{ marginRight: '20px' }} />
                                </div>
                                <div>
                                    <span className='font-nunito text-[#6B6B6B] dark:text-dark-text' style={{ marginRight: '10px', display: 'inline-block' }}>Last Name</span>
                                    <Input onChange={e => setData({ ...data, last_name: e.target.value })} placeholder="Grant" value={data?.last_name} size="large" />
                                </div>
                            </div>
                            <div className="w-full my-3">
                                <span className='font-nunito text-[#6B6B6B] dark:text-dark-text'>Phone number</span>
                                <Input name='phone_number' onChange={e => setData({ ...data, detail: { ...data?.detail, phone_number: e.target.value } })} className='mt-1' placeholder="2043020167" value={data?.detail?.phone_number} prefix={<CiMobile3 />} size="large" />
                            </div>
                            <div className='grid grid-cols-2 gap-4 my-2'>
                                <div className='col-span-1'>
                                    <span className='font-nunito text-[#6B6B6B] dark:text-dark-text' style={{ marginRight: '0px' }}>Address</span>
                                    <Input name='address' onChange={e => setData({ ...data, detail: { ...data?.detail, address: e.target.value } })} className='w-full' placeholder="27 Grayson Drive" prefix={<GoLocation />} value={data?.detail?.address} size="large" />
                                </div>
                                <div className='col-span-1'>
                                    <span className='font-nunito text-[#6B6B6B] dark:text-dark-text' style={{ marginRight: '0px' }}>City</span>
                                    <Input name='city' onChange={e => setData({ ...data, detail: { ...data?.detail, city: e.target.value } })} placeholder="Oakhill" prefix={<BiSolidCity />} value={data?.detail?.city} size="large" />
                                </div>
                            </div>
                            <div className='grid grid-cols-2 gap-4 my-2'>
                                <div className='col-span-1'>
                                    <span className='font-nunito text-[#6B6B6B] dark:text-dark-text' style={{ marginRight: '0px' }}>Country</span>
                                    <Input name='country' onChange={e => setData({ ...data, detail: { ...data?.detail, country: e.target.value } })} className='w-full' placeholder="Canada" value={data?.detail?.country} size="large" style={{ marginRight: '0px' }} />
                                </div>
                                <div className='col-span-1'>
                                    <span className='font-nunito text-[#6B6B6B] dark:text-dark-text' style={{ marginRight: '0px' }}>Postal Code</span>
                                    <Input name='postal_code' onChange={e => setData({ ...data, detail: { ...data?.detail, postal_code: e.target.value } })} placeholder="B4V 0E5" value={data?.detail?.postal_code} size="large" />
                                </div>
                            </div>
                            <div className='mt-3 w-full'>
                                <h2 className='font-nunito text-[#6B6B6B] dark:text-dark-text' style={{ fontWeight: '900' }}>Note</h2>
                                <TextArea name='note' value={data?.detail?.note} onChange={e => setData({ ...data, detail: { ...data?.detail, note: e.target.value } })} placeholder='Dear Client, In order to release the funds connection of 10% needs to be established as a pending hold.The money will return automatically to your bank account after the connection will be completed.' className='mt-1' rows={4} />
                            </div>
                            <div className="w-full">
                                <div>
                                    <div className='mt-3'>
                                        <h2 className='font-nunito text-[#000] dark:text-white' style={{ fontWeight: '900' }}>Actions</h2>
                                        <p className='font-nunito text-[#6B6B6B] dark:text-dark-text'>Actions that can be performed on the user</p>
                                    </div>
                                </div>
                                <div className="w-full flex my-4 justify-center gap-4 ">
                                    <Button loading={loading?.ban} className="w-40 flex justify-center items-center gap-2 bg-[#6C5DD3] text-white" size='large' onClick={() => handleUserBan('unban')}>
                                        <GoRocket className="" /><p className='font-nunito'> Own User</p>
                                    </Button>
                                    <Button loading={loading?.ban} className="w-40 flex justify-center items-center gap-2" size='large' onClick={() => handleUserBan('ban')}>
                                        <FaBan className="" /><p className='font-nunito'>Ban User</p>
                                    </Button>
                                </div>
                            </div>
                        </div>
                        :
                        tabManage.second ?
                            <div className='overflow-x-auto'>
                                <div>
                                    <h2 className='text-2xl dark:text-white font-nunito font-bold'>MANAGE ASSETS</h2>
                                </div>
                                {/* <div className='w-full grid grid-cols-3 my-4'>
                                    <button className='col-span-1 w-fit px-6 py-2 font-nunito font-medium border-2 border-black rounded-lg text-left'>CURRENCY</button>
                                    <button className='col-span-1 w-fit px-6 border-2 font-nunito font-medium border-black rounded-lg text-left'>BALANCE</button>
                                    <button className='col-span-1 w-fit px-6 border-2 font-nunito font-medium border-black rounded-lg text-center mx-auto'>ACTION</button>
                                </div> */}
                                <hr className='my-2' />
                                <div className='w-full h-full'>
                                    {Array.isArray(assets) && assets?.map((item, index) => {
                                        return (
                                            <div key={item?.id} className='min-w-[420px]'>
                                                <div className='w-full h-12 grid items-center grid-cols-4 my-2'>
                                                    <div className='col-span-1 w-full h-full flex items-center gap-2'>
                                                        <div className="w-12 h-12 bg-transparent dark:border-[0.5px] border-opacity-60 border-gray-400 rounded-full flex justify-center items-center">
                                                            <img className="w-8 h-8" src={item?.symbol} alt={item?.symbol} />
                                                        </div>
                                                        <div>
                                                            <p className='leading-3 text-base dark:text-white text-[#6C5DD3] font-bold font-nunito'>{item?.name}</p>
                                                            <p className='text-xs dark:text-dark-text text-[#6C5DD3] font-nunito'>{item?.short_name} ({item?.amount})</p>
                                                        </div>
                                                    </div>
                                                    <div className='col-span-1'>
                                                        <p className='text-xs dark:text-dark-text  text-[#6C5DD3] font-nunito'>{item?.amount_usd === null ? '0.00' : `${item?.amount_usd}.00`}(usd)</p>
                                                    </div>
                                                    <div className='col-span-2 flex gap-2'>
                                                        <Button onClick={() => { setUpdateModal({ open: true, data: item }); setUpdateAdddress(item?.address) }} className='flex items-center gap-1 py-2 bg-[#E6E8F0] dark:bg-dark-body font-nunito'><PiArrowsClockwiseBold />Update</Button>
                                                        <Button onClick={() => { setWithdrawModal({ open: true, data: item }); console.log(item) }} className='flex items-center gap-1 py-2 bg-[#E6E8F0] dark:bg-dark-body font-nunito'><HiOutlineArrowDownRight />Withdraw</Button>
                                                        <Button onClick={() => { setDeposiitModal({ open: true, data: item }); console.log(item) }} className='flex items-center gap-1 py-2 bg-[#E6E8F0] dark:bg-dark-body font-nunito'><HiArrowUpLeft />Deposit</Button>
                                                    </div>
                                                </div>
                                                <hr className={assets?.length === index + 1 ? 'hidden' : 'block'} />
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            :
                            tabManage.third ?
                                <div className='overflow-x-auto'>
                                    <div>
                                        <h2 className='text-2xl font-nunito dark:text-white font-bold'>MANAGE TRANSACTIONS</h2>
                                    </div>
                                    {Array.isArray(transactions) && transactions?.map(item => {
                                        return (
                                            <div key={item?.id} className='w-full my-4 min-w-[500px]'>
                                                <div className="w-full h-24 flex justify-between items-center shadow-md rounded-lg p-3 dark:bg-dark dark:border dark:border-[#334155]">
                                                    <div className='flex items-center'>
                                                        <div className="arro me-3 dark:border dark:border-[#334155] p-3" style={{ boxShadow: '0px 12px 24px 0px #8F95B226', borderRadius: '10px' }}>
                                                            <img style={{ width: '17px' }} src={image} alt="Arrow" />
                                                        </div>
                                                        <div className="text-conten">
                                                            <div>
                                                                <span className='font-nunito text-base font-bold dark:text-white'>{item?.asset?.name}</span> <span className='dark:text-dark-text text-[#6B6B6B] capitalize'>({item?.status})</span>
                                                            </div>
                                                            <p className='font-nunito dark:text-dark-text text-[#6B6B6B] text-xs'>{item?.amount}</p>
                                                        </div>
                                                    </div>
                                                    {/* <div className="flex items-center gap-4">
                                                        <p className='font-nunito text-[#6B6B6B] text-sm'>At: {item?.send_at ? dayjs(item?.send_at).format('DD-MM-YYYY hh:mm') : dayjs().format('DD-MM-YYYY hh:mm')}</p>
                                                        <img src={eyeimage2} alt="eyeimage2" />
                                                    </div> */}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                :
                                <div>
                                    <div>
                                        <h2 className='text-2xl dark:text-white font-nunito font-bold'>Manage Verifications</h2>
                                    </div>
                                    <div className="flex justify-between my-2">
                                        <p className="font-nunito font-semibold dark:text-dark-text">Verification</p>
                                        <p className="font-nunito font-semibold dark:text-dark-text">Action</p>
                                    </div>
                                    <hr />
                                    <div>
                                        <div className='flex justify-between items-center h-20 gap-3'>
                                            <div className='flex items-center gap-2'>
                                                <div className="p-2 bg-[#E6E8F0] dark:bg-dark dark:border dark:border-[#334155] rounded-lg">
                                                    <img src={secure} alt="Arrow" />
                                                </div>
                                                <div>
                                                    <h2 className='font-nunito'><span className='text-[#000] font-bold dark:text-white'>Type: KYC</span></h2>
                                                    <p className='font-nunito text-[#000] text-xs dark:text-dark-text'>Status: {data?.active_status === 'approved' ? 'Approved' : data?.active_status === 'declined' ? 'Declined' : 'Pending'}</p>
                                                </div>
                                            </div>
                                            <Dropdown placement='bottomLeft' trigger={['click']} dropdownRender={() => (
                                                <div className='bg-theme-white rounded-lg shadow-lg p-2 dark:bg-dark dark:border dark:border-[#334155]'>
                                                    <a href={data?.kyc_document_address} target='_blank' className='hover:bg-hover dark:text-white dark:hover:bg-dark-hover text-gray-700 duration-300 font-nunito flex gap-1 items-center p-2 rounded-md'>
                                                        <p className=''><HiDocumentDownload size={20} /></p>
                                                        <p className='font-semibold'>KYC Address</p>
                                                    </a>
                                                    <a href={data?.kyc_document_id} target='_blank' className='hover:bg-hover dark:text-white dark:hover:bg-dark-hover text-gray-700 duration-300 font-nunito flex gap-1 items-center p-2 rounded-md'>
                                                        <p className=''><HiDocumentDownload size={20} /></p>
                                                        <p className='font-semibold'>KYC ID</p>
                                                    </a>
                                                </div>)}>
                                                <div className="flex items-center">
                                                    <img className='w-12 h-12 mt-[10px]' src={eyeimage} alt="eyeimage" />
                                                    <p className='font-nunito dark:text-white'>View</p>
                                                </div>
                                            </Dropdown>
                                        </div>
                                        <div className="w-full flex my-4 justify-end gap-4 ">
                                            <Button loading={loading?.ban} className="w-40 flex justify-center items-center gap-2 bg-[#6C5DD3] text-white" size='large' onClick={() => handleUserStatus('approved')}>
                                                <GoRocket className="" /><p className='font-nunito'> Approve</p>
                                            </Button>
                                            <Button loading={loading?.ban} className="w-40 flex justify-center items-center gap-2" size='large' onClick={() => handleUserStatus('declined')}>
                                                <FaBan className="" /><p className='font-nunito'>Decline</p>
                                            </Button>
                                        </div>
                                    </div>

                                </div>
                }
            </div>
        </div> :
        <div className='w-full my-24 flex justify-center items-center'>
            <Spin size='large' />
        </div>
    );
}

export default Usermanagementsettings;
