import React, { useEffect, useState } from 'react'
import darkmode from '../../assests/icons/Vector2.png'
import language from '../../assests/icons/Frame.png'
import bell from '../../assests/icons/bell.png'
import avatar from '../../assests/icons/Oval.png'
import { Dropdown } from 'antd'
import { BiLogOut } from 'react-icons/bi'
import axios from 'axios'
import { BASE_URL } from '../../utils/config'
import { useNavigate } from 'react-router'
import { BsSunFill, BsFillMoonStarsFill } from 'react-icons/bs'
import { HiLanguage } from 'react-icons/hi2'
import { BiBell } from 'react-icons/bi'
import { BsFillSunFill, BsBell } from 'react-icons/bs'
import { RiMenu2Fill } from 'react-icons/ri'
import { LiaLanguageSolid } from 'react-icons/lia'
import { useDispatch, useSelector } from 'react-redux'
import { chnageTheme } from '../../redux/reducer/themeSlice'


const Header = ({ setShowSiderbar }) => {
    const [dark, setDark] = useState(localStorage.getItem('theme'));
    const title = useSelector(state => state.header.title)
    const navigate = useNavigate();
    const name = title?.split(',')?.[0]
    const message = title?.split(',')?.[1]
    const dispatch = useDispatch();
    const theme = useSelector(state => state?.theme?.value);
    const data = useSelector(state => state.user.value)
    const avatar1 = `${window.location.origin}/avatar1.png`
    const user = JSON.parse(localStorage.getItem('user'));




    const handleLogout = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${BASE_URL}logout`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'type': 'Bearer'
                }
            })
            if (response) {
                console.log('Logout successful');
                localStorage.removeItem('token');
                localStorage.removeItem('timestamp');
                localStorage.removeItem('time');
                navigate('/login');
                return;
            }
        }
        catch (err) {
            console.log(err)
        }
    }


    const handleDarkMode = () => {
        setDark(prev => prev === 'dark' ? 'light' : 'dark');
    }

    useEffect(() => {
        if (dark === 'dark') {
            dispatch(chnageTheme(dark));
            localStorage.setItem('theme', 'dark');
            document.documentElement.classList.add('dark')
        }
        else {
            dispatch(chnageTheme(dark));
            document.documentElement.classList.remove('dark')
            localStorage.setItem('theme', 'light');
        }
    }, [dark])

    return (
        <div className='w-full flex justify-between items-end'>
            <div>
                <p className='font-nunito font-bold dark:text-white leading-4'>Hi {name},</p>
                <div className='flex items-center'>
                    <div className='flex items-center gap-1 '>
                        <RiMenu2Fill className='visible xl:hidden' onClick={() => setShowSiderbar(true)} size={24} />
                        <h1 className='text-xl md:text-2xl lg:text-3xl text-nowrap font-nunito dark:text-dark-text text-[#081735] font-bold'>
                            {message}
                        </h1>
                    </div>
                </div>
            </div>
            <ul className='h-full flex gap-6 items-center'>
                <li onClick={handleDarkMode} className='w-10 h-10 rounded-full cursor-pointer dark:border dark:border-[#334155] dark:bg-dark bg-theme-white hidden min-[525px]:flex items-center justify-center shadow-xl'>
                    <p className='dark:text-white text-[#7EBA00]'>{theme !== 'dark' ? <BsSunFill size={24} /> : < BsFillMoonStarsFill />}
                    </p>
                </li>
                <li className='w-10 h-10 rounded-full dark:border dark:border-[#334155] bg-theme-white dark:bg-dark hidden min-[525px]:flex cursor-pointer items-center justify-center shadow-xl'>
                    <p className='dark:text-white text-[#7EBA00]'><LiaLanguageSolid size={24} /></p>
                </li>
                <li className='w-10 h-10 rounded-full dark:border dark:border-[#334155] bg-theme-white dark:bg-dark hidden min-[525px]:flex cursor-pointer items-center justify-center shadow-xl'>
                    <p className='dark:text-white text-[#7EBA00]'> <BsBell size={24} /></p>
                </li>
                <Dropdown placement='bottomLeft' trigger={['click']}
                    dropdownRender={() => (
                        <div className='dark:bg-dark dark:border dark:border-[#334155] bg-theme-white cursor-pointer w-40 shadow-md h-fit rounded-lg p-2'>
                            <li onClick={handleLogout} className='flex gap-2 items-center h-10 hover:bg-hover dark:hover:bg-dark-hover rounded-md transition-all duration-300 p-2'>
                                <BiLogOut size={24} /><p className='font-nunito text-lg font-semibold'>Logout</p>
                            </li>
                            <li onClick={handleDarkMode} className='flex min-[525px]:hidden gap-2 items-center h-10 hover:bg-hover dark:hover:bg-dark-hover rounded-md transition-all duration-300 p-2'>
                                {theme !== 'dark' ? <BsSunFill size={24} /> : < BsFillMoonStarsFill />}
                                <p className='font-nunito text-lg font-semibold'>Darkmode</p>
                            </li>
                            <li className='flex min-[525px]:hidden gap-2 items-center h-10 hover:bg-hover dark:hover:bg-dark-hover rounded-md transition-all duration-300 p-2'>
                                <HiLanguage size={24} />
                                <p className='font-nunito text-lg font-semibold'>Language</p>
                            </li>
                            <li className='flex min-[525px]:hidden gap-2 items-center h-10 hover:bg-hover dark:hover:bg-dark-hover rounded-md transition-all duration-300 p-2'>
                                <BiBell size={24} />
                                <p className='font-nunito text-lg font-semibold'>Notification</p>
                            </li>
                        </div>
                    )}
                >
                    <li className='w-10 h-10 rounded-full bg-theme-white dark:border dark:border-[#334155] dark:bg-dark flex cursor-pointer items-center justify-center shadow-xl'>
                        <img className='w-10 h-10 rounded-full' src={data?.avatar_url ? data?.avatar_url : user?.avatar_url ? user?.avatar_url : avatar1} alt="item" />
                    </li>
                </Dropdown>
            </ul>
        </div>
    )
}

export default Header;