import React, { useEffect, useState } from 'react'
import { Button, Input, Spin } from "antd";
import Ticket from '../../../assests/images/ph_ticket-fill.png'
import Cancel from '../../../assests/images/cancel.png';
import Pen from '../../../assests/images/pen.png'
import { useDispatch } from "react-redux";
import { changeHeader } from '../../../redux/reducer/headerSlice'
import handlePost from '../../../utils/Post';
import { BASE_URL } from '../../../utils/config';
import AntDTable from '../../../components/table';
import handleGet from '../../../utils/Get';
import { RxCross2 } from 'react-icons/rx'


const { TextArea } = Input;

function Feedback() {

    const [createTicket, setCreateTicket] = useState(false);
    const [ticketDetail, setTicketDetail] = useState({ title: '', detail: '' });
    const [data, setData] = useState('');
    const [tabManage, setTabManage] = useState({ first: true, second: false, third: false })
    const [loader, setLoader] = useState(true);
    const user = JSON.parse(localStorage.getItem('user'));
    const url = `${BASE_URL}ticket/store`;
    const dispatch = useDispatch();



    useEffect(() => {
        dispatch(changeHeader(`${user.name}, Feedback & Support`));
    }, [user])

    const handleCreateTicket = async () => {
        try {
            const success = await handlePost(url, ticketDetail);
            if (success) {
                setTicketDetail('')
                setCreateTicket(false)
                fetchData(tabManage.first ? 'ticket/user/pending/list' : tabManage.second ? 'ticket/user/open/list' : 'ticket/user/closed/list')
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    const fetchData = async (ticket) => {
        setLoader(true);
        const data = await handleGet(`${BASE_URL}${ticket}`)
        if (data) {
            setData(data?.ticket)
            setLoader(false);
        }
    }
    useEffect(() => {
        fetchData('ticket/user/pending/list')
    }, [])


    return (
        <>
            {!createTicket ?
                <div>
                    <div className='w-full flex justify-end'>
                        <Button size='large' onClick={() => setCreateTicket(true)} className='w-full max-w-[220px] my-4 bg-theme-white dark:bg-dark font-medium text-base font-nunito '>Create New Tickets?</Button>
                    </div>
                    <div className='w-full h-full overflow-auto dark:bg-dark dark:border dark:border-[#334155] bg-theme-white p-2 rounded-xl'>
                        <div className='m-2 flex gap-2'>
                            <Button size='large' onClick={() => { setTabManage({ first: true, second: false, third: false }); fetchData('ticket/user/pending/list') }} className={`${tabManage.first ? 'bg-[#6C5DD3] text-white' : 'text-[#6B6B6B] bg-[#F3F3F3] dark:bg-dark-body dark:text-dark-text'} w-44 p-2 rounded-lg font-medium font-nunito`}>Pending Tickets</Button>
                            <Button size='large' onClick={() => { setTabManage({ first: false, second: true, third: false }); fetchData('ticket/user/open/list') }} className={`${tabManage.second ? 'bg-[#6C5DD3] text-white' : 'text-[#6B6B6B] bg-[#F3F3F3] dark:bg-dark-body dark:text-dark-text'} w-32 p-2 rounded-lg font-medium font-nunito`}>Open</Button>
                            {/* <button onClick={() => { setTabManage({ first: false, second: false, third: true }); fetchData('ticket/user/closed/list') }} className={`${tabManage.third ? 'bg-[#6C5DD3] text-white' : 'text-[#6B6B6B] bg-[#F3F3F3]'} w-32 p-2 rounded-lg font-nunito`}>Close</button> */}
                        </div>
                        {
                            loader ?
                                <div className='min-h-[420px] h-full w-full flex items-center justify-center'>
                                    <Spin size='large' />
                                </div>
                                :
                                <div className='flex w-full min-w-[880px] min-h-[420px] justify-center'>
                                    <AntDTable link='/user' loader={loader} data={data} />
                                </div>}
                    </div>
                </div>
                :
                <div className="flex justify-center my-12">

                    <div className="w-full max-w-xl dark:bg-dark dark:border dark:border-[#334155] bg-theme-white rounded-lg shadow-lg p-4">
                        <div className="">
                            <div className="flex items-center gap-4">
                                <img className='w-16 h-16' src={Ticket} alt="Ticket" />
                                <div className="col-md-10 d-flex flex-column justify-content-space-between">
                                    <h2 className='text-2xl dark:text-white font-bold font-nunito'>Create a new Ticket</h2>
                                    <p className='font-nunito text-gray-400'>Fill in the form below to cteate  a new ticket</p>
                                </div>
                            </div>
                            <div className="ml-4 my-4">
                                <p className="font-nunito text-gray-400">Ticket Title</p>
                                <Input onChange={e => setTicketDetail({ ...ticketDetail, title: e.target.value })} value={ticketDetail.title} className='my-1' size='large' placeholder="Example: I can't purchase anything" />
                            </div>
                            <div className="ml-4 my-4">
                                <p className="font-nunito text-gray-400">Long Description</p>
                                <TextArea onChange={e => setTicketDetail({ ...ticketDetail, detail: e.target.value })} value={ticketDetail.detail} rows={4} placeholder="Example: I can't purchase anything" />
                            </div>

                            <div className="grid grid-cols-2 gap-2 ml-4 mt-4">
                                <Button size='large' onClick={() => setCreateTicket(false)} className='bg-theme-white h-12 dark:bg-dark-body flex justify-center items-center'>
                                    <RxCross2 className='mt-[1' size={20} />
                                    <p className='mt-[3px]'>Cancel</p>
                                </Button>
                                <Button onClick={handleCreateTicket} className='flex col-span-1 w-full h-10 bg-hoverUser dark:bg-dark-button-color font-nunito text-white rounded-lg items-center justify-center'>
                                    <img className='h-5' src={Pen} alt="" style={{ width: '15px', marginRight: '5px' }} />
                                    <p>Create</p>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
export default Feedback