import React, { useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
import { BsSearch } from 'react-icons/bs'
import logo from '../../assests/icons/Group.png';
import Logo from '../../assests/images/PayBack.png';
import dashboard from '../../assests/images/colorIcon.png';
import chart from '../../assests/images/Chart.png';
import pending from '../../assests/images/Combined-Shape.png';
import support from '../../assests/images/Wallet.png';
import avatarImage from '../../assests/icons/Oval.png';
import list from '../../assests/icons/list.png';
import Bag from '../../assests/images/Bag.png';
import transaction from '../../assests/icons/usertransaction.png';
import activeDashboard from '../../assests/icons/activedashboard.png';
import activeBuy from '../../assests/icons/activebag.png';
import activeChart from '../../assests/icons/activechart.png';
import activeTransaction from '../../assests/icons/activetransaction.png';
import activeSwap from '../../assests/icons/activewallet.png';
import activeSupport from '../../assests/icons/activeuser.png';
import handleRefreshToken from '../../utils/RefreshToken';
import { useSelector } from 'react-redux';


const SideBar = ({ setShowSiderbar }) => {
    const avatar1 = `${window.location.origin}/avatar1.png`
    const location = useLocation();
    const path = location?.pathname?.split('/')?.[2];
    const user = JSON.parse(localStorage.getItem('user'));
    const data = useSelector(state => state.user.value)

    const navItem = [
        {
            activeIcon: activeDashboard,
            icon: dashboard,
            title: 'Dashboard',
            url: 'dashboard'
        },
        {
            activeIcon: activeBuy,
            icon: Bag,
            title: 'Buy/Sell Crypto',
            url: 'buysell'
        },
        {
            activeIcon: activeChart,
            icon: chart,
            title: 'My Assets',
            url: 'assets'
        },
        {
            activeIcon: activeTransaction,
            icon: transaction,
            title: 'Transactions',
            url: 'transaction'
        },
        {
            activeIcon: activeTransaction,
            icon: transaction,
            title: 'Explore Blockchain',
            url: 'https://blockchair.com/'
        },
        {
            activeIcon: activeSwap,
            icon: support,
            title: 'Swap Crypto',
            url: 'swapcrypto'
        },
        {
            activeIcon: activeSupport,
            icon: pending,
            title: 'Feedback & Support',
            url: 'support'
        },
    ];

    useEffect(() => {
        const time = Number(localStorage.getItem('time'));
        const interval = setInterval(() => {
            if (time + 40 * 60 * 1000 < Date.now()) {
                handleRefreshToken();
                localStorage.setItem('time', Date.now());
                localStorage.getItem('timestamp', Date.now() + 1000 * 60 * 60);
            }
        }, 5000)
        return () => clearInterval(interval);
    })




    return (
        <div className='h-screen bg-theme-white overflow-x-hidden dark:bg-dark overflow-y-auto'>
            <div className='px-4 py-8'>
                <div className='flex h-20 justify-center'>
                    <img className='h-26' src={Logo} alt="logo" style={{ width: "100%", height: "120px" }} />
                    <BiArrowBack className='visible xl:hidden' onClick={() => setShowSiderbar(false)} size={24} />
                </div>
                <div className='my-10'>
                    {navItem.map(({ title, icon, url, activeIcon }, index) => {
                        return (
                            <NavLink
                                onClick={() => setShowSiderbar(false)}
                                className={`${url === path ? 'bg-hoverUser hover:bg-hoverUser dark:hover:bg-dark-active dark:bg-dark-active' : (path === undefined && index === 0) ? 'bg-hoverUser hover:bg-hoverUser dark:hover:bg-dark-active dark:bg-dark-active' : ''} flex h-12 my-2 items-center p-3 gap-3 rounded-xl transition-colors duration-300 dark:hover:bg-dark-hover hover:bg-hover`}
                                to={url}
                                key={index}
                                target={title === 'Explore Blockchain' ? '_blank' : '_self'}
                            >
                                {title === 'Explore Blockchain' ? <BsSearch color='#6C5DD3' size={24} /> : <img src={path === url ? activeIcon : (path === undefined && index === 0) ? activeIcon : icon} alt="icon" />}
                                <p className={`${path === url ? 'text-white' : (path === undefined && index === 0) ? 'text-white' : 'text-[#6C5DD3]'}  text-base font-semibold`}>{title}</p>
                            </NavLink>
                        )
                    })}
                </div>
                <div className='w-full flex justify-center'>
                    <div className='w-full h-[1px] bg-line' />
                </div>
                <Link to='profile' className='relative w-full flex h-16 gap-3 items-center p-3 mt-14'>
                    <img className='w-12 h-12 rounded-full' src={data?.avatar_url ? data?.avatar_url : user?.avatar_url ? user?.avatar_url : avatar1} alt="avatarImage" />
                    <div>
                        <p className='text-sm font-bold leading-4 font-primary text-nowrap dark:text-white'>{user ? user?.name : ''}</p>
                        <p className='text-sm font-nunito text-[#6C5DD3] text-nowrap'>My Account</p>
                    </div>
                    <img className='absolute right-0 w-7 h-7 justify-self-end' src={list} alt="list" />
                </Link>
            </div>
        </div>
    );
}

export default SideBar;