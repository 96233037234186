import React, { useEffect, useState } from 'react';
import styles from '../auth.module.css'
import Logo from '../../assests/images/PayBack.png';
import { Checkbox, Divider, Input } from 'antd';
import { IoMailOutline, IoPersonOutline, IoLockClosedOutline } from "react-icons/io5";
import Google_logo from '../../assests/images/google.png'
import LinkedIn_logo from '../../assests/images/linkedIn.png'
import Twitter_logo from '../../assests/images/teitter.png'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { notification } from 'antd';
import { BASE_URL } from '../../utils/config';
import Bitimage from '../../assests/images/image.jpeg';



function SignUp() {

    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const userData = localStorage.getItem('user');
    const user = JSON.parse(userData);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const registrationData = {
                name: userName,
                email: userEmail,
                password: userPassword,
                type: 'user'
            };

            const response = await axios.post(`${BASE_URL}register`, registrationData);
            if (response?.data) {
                setLoading(false);
                console.log('Registration success:', response.data);
                const token = response.data.authorisation.token;
                const user = response?.data?.user;
                localStorage.setItem('user', JSON.stringify(user));
                localStorage.setItem('token', token);
                localStorage.setItem('timestamp', Date.now() + 3600 * 1000);
                localStorage.setItem('time', Date.now());
                notification.success({
                    message: response?.data?.message,
                    // placement: 'topLeft', // Position of the notification
                });
                if (user.type === 'admin') {
                    navigate('/admin');
                }
                else {
                    navigate('/user');
                }
                return;
            }
        } catch (error) {
            setLoading(false);
            if (error.response && error.response.data && error.response.data.errors) {
                const errors = error.response.data.errors;
                if (errors.name) {
                    notification.error({
                        message: errors.name[0],
                    });
                    return;
                }
                if (errors.email) {
                    notification.error({
                        message: errors.email[0],
                    });
                    return;
                }
                if (errors.password) {
                    notification.error({
                        message: errors.password[0],
                    })
                    return;
                }
            } else {
                console.error('Registration error:', error);
                notification.error({
                    message: 'An error occurred during registration.',
                });
            }
        }
    };

    useEffect(() => {
        if (token && user) {
            if (user.type === 'admin') {
                navigate('/admin');
            }
            else if (user?.type === 'user') {
                navigate('/user')
            }
        }

    }, [])


    return (!token &&
        <div className="w-full h-[100vh] overflow-hidden dark:bg-dark">
            <div className="w-full grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
                <div className="hidden lg:flex lg:col-span-1 xl:col-span-2">
                    <img className='w-full h-[100vh]' src={Bitimage} alt="SignUp Image" />
                </div>
                <div className='col-span-1 p-8 h-[100vh] flex flex-col items-center overflow-x-hidden overflow-y-auto'>
                    <div className='w-full max-w-md'>
                        <div className='flex items-center gap-4 pt-4 mb-2 -ml-6'>
                            <img src={Logo} alt="Logo" style={{ height: "120px" }} />
                            {/* <span className='text-[#6C5DD3] text-3xl font-nunito font-semibold'>LOGO HERE</span> */}
                        </div>
                        <div className="row pb-4">
                            <div className="col-md-12">
                                <h3 className='text-2xl dark:text-white text-[#081735] font-bold font-nunito'>Hi, Welcome to <span className='text-[#6C5DD3]'>My Wallet!</span></h3>
                            </div>
                            <div className="col-md-12">
                                <span className='text-base dark:text-dark-text text-[#8F95B2] font-semibold font-nunito'>Let's start by creating an account</span>
                            </div>
                        </div>
                        <div className="w-full flex gap-2 flex-wrap">
                            <div className="flex items-center gap-2 border rounded py-2 px-4 w-fit h-fit">
                                <img src={Google_logo} alt="" />
                                {/* <span className='font-nunito text-base'> Sign in with Google</span> */}
                            </div>
                            <div className='border rounded flex items-center justify-center w-fit py-2 px-4'>
                                <img src={LinkedIn_logo} alt="" />
                            </div>
                            <div className='border rounded flex items-center justify-center w-fit py-2 px-4'>
                                <img src={Twitter_logo} alt="" />
                            </div>
                        </div>
                        <Divider plain>Or sign in with email</Divider>
                        <div className="row py-1">
                            <div className="col-md-12">
                                <span className='dark:text-dark-text'>Name</span>
                                <Input
                                    onKeyDown={(e) => e.key === 'Enter' && handleSubmit(e)}
                                    placeholder="Jane Eyre"
                                    prefix={<IoPersonOutline />}
                                    size="large"
                                    onChange={(e) => setUserName(e.target.value)}
                                />
                            </div>

                            <div className="col-md-12 py-3">
                                <span className='dark:text-dark-text'>Email address</span>
                                <Input
                                    onKeyDown={(e) => e.key === 'Enter' && handleSubmit(e)}
                                    placeholder="Email Address"
                                    prefix={<IoMailOutline />}
                                    size="large"
                                    onChange={(e) => setUserEmail(e.target.value)}
                                />
                            </div>
                            <div className="col-md-12">
                                <span className='dark:text-dark-text'>Password</span>
                                <Input.Password
                                    onKeyDown={(e) => e.key === 'Enter' && handleSubmit(e)}
                                    placeholder="Min. 8 characters"
                                    prefix={<IoLockClosedOutline />}
                                    size="large"
                                    onChange={(e) => setUserPassword(e.target.value)}
                                />
                            </div>

                        </div>
                        <div className="row py-3">
                            <div className="col-md-12">
                                <Checkbox defaultChecked={true} >I agree to the <span>Terms & Condition</span></Checkbox>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <button disabled={loading} className={`${styles.LoginButton} ${loading ? 'opacity-70' : ''}`} onClick={handleSubmit}>Sign Up</button>
                            </div>
                        </div>
                        <div className="row py-4">
                            <div className="col-md-12">
                                <span className='dark:text-white'>Already have an account?<Link to='/login' style={{ color: '#8F95B2', fontWeight: '600', borderBottom: '1px solid #8F95B2', marginLeft: '5px', cursor: 'pointer' }}>Login</Link></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUp