import React, { useEffect, useState } from 'react'
import styles from './BuySell.module.css';
import { Button, InputNumber, Progress } from 'antd';
import righticon from '../../../assests/icons/righticon.png'
import centerimg from '../../../assests/images/buy-sell-img.png'
import { Select } from "antd";
import { useDispatch } from "react-redux";
import { changeHeader } from '../../../redux/reducer/headerSlice'
import { MdOutlineTaskAlt } from 'react-icons/md'

function BuySell() {


  const [tabManage, setTabManage] = useState({ first: true, second: false, third: false });
  const [buttonManage, setButtonManage] = useState(true)
  const user = JSON.parse(localStorage.getItem('user'));
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeHeader(`${user.name}, Buy & Sell Crypto`));
  }, [user])

  return (
    <div className='w-full h-full'>
      {/* <div className='w-full h-full fixed backdrop-blur-xl z-10 cursor-not-allowed bg-[#fff] opacity-50'></div> */}
      <div className='grid grid-cols-1 md:grid-cols-2 gap-2'>
        <div className='bg-theme-white dark:bg-dark dark:border dark:border-[#334155] col-span-1 rounded-lg p-4'>
          <div className="d-flex justify-content-center">
            <div className=''>
              <button onClick={() => setButtonManage(true)} className={`${buttonManage ? 'bg-[#7EBA00] dark:bg-dark-button-color text-white' : 'text-[#6B6B6B] bg-[#F3F3F3] dark:bg-[#334155] dark:text-[#94a3b8]'} w-24 p-2 rounded-lg font-nunito`}>Buy</button>
              <button onClick={() => setButtonManage(false)} className={`${!buttonManage ? 'bg-[#7EBA00] dark:bg-dark-button-color text-white' : 'text-[#6B6B6B] bg-[#F3F3F3] dark:bg-[#334155] dark:text-[#94a3b8]'} w-24 p-2 rounded-lg font-nunito`}>Sell</button>
            </div>
          </div>
          {tabManage.first ?
            <div className=''>
              <Progress percent={30} status="active" showInfo={false} strokeColor="#6C5DD3" className={`${styles.progress}`} />
              <div className='pt-2 pb-2'>
                <Select disabled
                  className='w-full'
                  size='large'
                  defaultValue="BTC"
                  options={[
                    { value: 'Bitcoin', label: 'BTC' },
                    { value: 'Ethereum', label: 'ETH' },
                    { value: 'Ethereum', label: 'ETH' },
                    { value: 'Ripple', label: 'XRP' },
                    { value: 'Litecoin', label: 'LTC' },
                    { value: 'Cardano', label: 'ADA' },
                  ]}
                />
              </div>
              <div className='pt-2 pb-2'>
                <InputNumber disabled className='w-full' size='large' type='number' placeholder='0.00' />
              </div>
              <div className={styles.divdetail}>
                <div className={`flex justify-between font-nunito mt-2`}>
                  <div className="flex items-center">
                    <div className={styles.headingdetail}>
                      <p className="text-left dark:text-dark-text">Exchange Rate</p>
                    </div>
                  </div>
                  <div className={styles.minus}>
                    <p className="text-right me-3 dark:text-dark-text">-</p>
                  </div>
                </div>

                <div className={`flex justify-between font-nunito mt-2`}>
                  <div className="flex items-center">
                    <div className={styles.headingdetail}>
                      <p className="text-left dark:text-dark-text">Offered by</p>
                    </div>
                  </div>
                  <div className={styles.minus}>
                    <p className="text-right me-3 dark:text-dark-text">-</p>
                  </div>
                </div>

                <div className={`flex justify-between font-nunito mt-2`}>
                  <div className="flex items-center">
                    <div className={styles.headingdetail}>
                      <p className="text-left dark:text-dark-text">Network Fee</p>
                    </div>
                  </div>
                  <div className={styles.minus}>
                    <p className="text-right me-3 dark:text-dark-text">-</p>
                  </div>
                </div>

                <div className={`flex justify-between font-nunito mt-2`}>
                  <div className="flex items-center">
                    <div className={styles.headingdetail}>
                      <p className="text-left dark:text-dark-text">Wallet Fee</p>
                    </div>
                  </div>
                  <div className={styles.minus}>
                    <p className="text-right me-3 dark:text-dark-text">-</p>
                  </div>
                </div>

                <div className={`flex justify-between font-nunito mt-2`}>
                  <div className="flex items-center">
                    <div className={styles.headingdetail}>
                      <p className="text-left dark:text-dark-text">Total</p>
                    </div>
                  </div>
                  <div className={styles.minus}>
                    <p className="text-right me-3 dark:text-dark-text">-</p>
                  </div>
                </div>
              </div>


              <div className='font-nunito mt-4'>
                <Button disabled size='large' onClick={() => setTabManage({ first: false, second: true, third: false })} className={`w-full bg-[#7EBA00] cursor-not-allowed`}>Buy Crypto</Button>
              </div>
            </div>

            :
            tabManage.second ?
              <div>
                <Progress percent={50} status="active" showInfo={false} strokeColor="#6C5DD3" className='w-full my-3' />
                <div className="flex items-center justify-between mt-2">
                  <h1 className="font-nunito text-2xl" style={{ fontWeight: '700' }}>Select Payment Method</h1>
                  <button className='w-fit h-fit border-2 min-w-[120px] border-[#7EBA00] rounded-md py-2 px-4'>Add New</button>
                </div>
                <hr className='w-full my-4' />
                <div className="font-nunito">
                  <p style={{ fontWeight: '600' }}>No payment methods found</p>
                </div>
                <div className='font-nunito mt-2'>
                  <button className='w-full bg-hoverUser h-12 rounded-lg font-nunito my-2 font-semibold text-white' onClick={() => setTabManage({ first: false, second: false, third: true })}>
                    Confirm
                  </button>
                  <button className='w-full bg-admin h-12 rounded-lg font-nunito my-2 font-semibold text-[6B6B6B]' onClick={() => setTabManage({ first: true, second: false, third: false })}>
                    Go Back
                  </button>
                </div>
              </div>
              :
              <div>
                <Progress percent={80} status="active" showInfo={false} strokeColor="#6C5DD3" className={`${styles.progress} mt-3 ms-3`} />
                <div className="flex flex-col items-center justify-center font-nunito">
                  <img
                    src={centerimg}
                    alt="Centered Image"
                    className="max-w-full max-h-64"
                  />
                  <h1 className="text-2xl" style={{ color: '#00000', fontWeight: '800', fontSize: '27px' }}>Are you sure?</h1>
                  <p className="text-lg text-center mx-auto" style={{ maxWidth: '400px', color: '#000000' }}>
                    You are about to Buy 0.999999233280532 BTC. Please confirm this action.
                  </p>
                </div>
                <div className='font-nunito ms-3 mt-1'>
                  <div>
                    <button className={styles.confirmbtn}>
                      Confirm
                    </button>
                  </div>
                  <div className='mt-3'>
                    <button className={styles.backbtn} onClick={() => setTabManage({ first: false, second: true, third: false })}>
                      Go Back
                    </button>
                  </div>
                </div>
              </div>

          }

        </div>
        <div className="col-span-1 dark:bg-dark dark:border dark:border-[#334155] bg-theme-white rounded-lg p-4 font-nunito">
          <h1 className='text-2xl font-bold text-[#000] dark:text-white'>Buy Crypto <span className={styles.spanheading}>with ease</span></h1>
          <div className='mt-3'>
            <p className='text-gray-400 font-nunito text-sm'>Use a credit card, debit card or bank account. Buy Bitcoin, Ethereum, USDT, USDC or other top cryptocurrencies.</p>
            <p className='text-gray-400 font-nunito text-sm'>*You can also sell crypto directly for cash into your bank account</p>
          </div>
          <div className="flex my-2 mt-4 -ml-1 gap-2">
            <p className='text-[#7EBA00] dark:text-[#6C5DD3]'><MdOutlineTaskAlt size={40} /></p>
            <div className='font-nunito '>
              <h1 className='text-xl font-bold text-[#7EBA00] dark:text-[#6C5DD3]'>Instant Approval</h1>
              <p className='w-60 text-gray-400 text-sm leading-4'>Get approved in minutes. Start buying crypto today.</p>
            </div>
          </div>
          <div className="flex my-2 mt-4 -ml-1 gap-2">
            <p className='text-[#7EBA00] dark:text-[#6C5DD3]'><MdOutlineTaskAlt size={40} /></p>
            <div className='font-nunito '>
              <h1 className='text-xl font-bold text-[#7EBA00] dark:text-[#6C5DD3]'>Lowest Fees</h1>
              <p className='w-60 text-gray-400 text-sm leading-4'>We offer the lowest fees on the market. No hidden fees.</p>
            </div>
          </div>
          <div className="flex my-2 mt-4 -ml-1 gap-2">
            <p className='text-[#7EBA00] dark:text-[#6C5DD3]'><MdOutlineTaskAlt size={40} /></p>
            <div className='font-nunito '>
              <h1 className='text-xl font-bold text-[#7EBA00] dark:text-[#6C5DD3]'>24/7 Support</h1>
              <p className='w-60 text-gray-400 text-sm leading-4'>Our support team is available 24/7 to help you with any questions.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default BuySell;