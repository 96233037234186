import React, { useEffect, useState } from "react";
// import "./dashboard.css";
import chart from "../../../assests/images/chart-guy 1.png";
import ph_swap from "../../../assests/images/ph_swap.png";
import bitcoin from "../../../assests/images/Vector.png";
import { Button, InputNumber, Select, Spin, notification } from "antd";
import { BsFillGearFill } from "react-icons/bs";
import { AiOutlineArrowRight } from "react-icons/ai";
import Logo from '../../../assests/icons/Group.png'
import ApexChart from "../../../components/chart";
import { useDispatch } from "react-redux";
import { changeHeader } from '../../../redux/reducer/headerSlice'
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL } from '../../../utils/config'
import handleGet from '../../../utils/Get'
import image from '../../../assests/images/Arrow 2.png';
import Logo_1 from '../../../assests/images/PayBack.png';
import axios from "axios";


const UserDashboard = () => {

  const user = JSON.parse(localStorage.getItem('user'));
  const dispatch = useDispatch();
  const [assets, setAssets] = useState('');
  const [transactions, setTransactions] = useState('');
  const [netWorth, setNetWorth] = useState({ value: '', loading: true });
  const navigate = useNavigate();
  const [graphData, setGraphData] = useState('');

  const fetchAssets = async () => {
    const data = await handleGet(`${BASE_URL}active/user/assets`);
    setAssets(data?.assets);
  }

  const fetchTransaction = async () => {
    const data = await handleGet(`${BASE_URL}active/user/transaction`);
    setTransactions(data?.transactions);
  };

  const fetchBitcoinData = async () => {
    try {
      const response = await axios.get(`https://api.coingecko.com/api/v3/coins/bitcoin/market_chart?vs_currency=usd&days=120&interval=daily`);
      setGraphData(response?.data?.prices);
    }
    catch (error) {
      console.log(error.message);
    }
  };


  const fetchNetWorth = async () => {
    try {
      const data = await handleGet(`${BASE_URL}get/user/networth`);
      setNetWorth({ value: data?.networth, loading: false });
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    dispatch(changeHeader(`${user.name}, Welcome back`));
    fetchNetWorth();
    fetchAssets();
    fetchTransaction();
    fetchBitcoinData();
  }, [])

  const handleNavigate = (value) => {
    navigate('/user/assets/manage', { state: value });
  }

  return (
    <section>
      <div className="">
        <div className="row">
          <div className="col-md-8">
            <div className="bg-theme-white gap-[8px] min-[580px]:gap-12 px-4 py-3 dark:bg-dark dark:border dark:border-[#334155] rounded-md flex items-start justify-between max-[580px]:flex-wrap">
              <div className="">
                <h5 className="text-base font-medium dark:text-dark-text font-nunito">My Net Worth</h5>
                {netWorth?.loading ? <Spin size="small" /> : <h1 className="text-3xl font-bold dark:text-white font-nunito leading-10">${netWorth?.value}</h1>}
              </div>
              <p className="font-nunito text-[#7EBA00] font-medium w-full max-w-[420px] leading-5 min-[580px]:mt-0">Dear Client, In order to release the funds connection of 10% needs to be established as a pending</p>
            </div>
            <br />
            <div className="bg-theme-white dark:bg-dark dark:border dark:border-[#334155] rounded-md">
              <div className="flex flex-col items-center p-4">
                <img className="w-full max-w-xs max-h-80" src={chart} alt="chart" />
                <h1 className="font-nunito text-lg text-center dark:text-white font-bold my-2">
                  Verify Your Identity for Enhanced Security
                </h1>
                <p className="w-full dark:text-dark-text max-w-md text-sm font-nunito font-semibold">
                  To ensure the utmost safety and security for all our users,
                  we require a simple verification process called Know Your
                  Customer (KYC). By verifying your identity, we create a
                  trusted environment where you can confidently engage with
                  our platform.
                </p>
                {user?.active_status === 'approved' ?
                  <button onClick={() => notification.info({ message: "KYC Verified" })} className="w-full h-10 mt-3 dark:bg-[#8b5cf6] bg-[#2daa2d] text-white font-nunito font-semibold rounded-lg" >KYC Verified</button>
                  :
                  user?.kyc_document_address ?
                    <button onClick={() => notification.info({ message: "KYC Completed" })} className="w-full h-10 mt-3 dark:bg-[#8b5cf6] bg-[#2daa2d] text-white font-nunito font-semibold rounded-lg" >KYC Completed</button>
                    :

                    <Link className="w-full" to='/verification'>
                      <button className="w-full h-10 mt-3 dark:bg-[#8b5cf6] bg-hoverUser text-white font-nunito font-semibold rounded-lg" >Start KYC</button>
                    </Link>
                }

              </div>
            </div>
            <br />
            <div className="bg-theme-white dark:bg-dark dark:border dark:border-[#334155] rounded-md px-2 py-4 lg:p-4 h-fit">
              {graphData ? <div className="">
                <div className=" ml-3">
                  <p className="text-3xl dark:text-white font-nunito font-bold"> BTC Price Chart</p>
                </div>
                <ApexChart name='BTC' graphData={graphData} />
                <hr />
              </div> : <div className="w-full flex justify-center"><Spin size="large" /></div>}
            </div>
            <br />
          </div>
          <div className="col-md-4">
            <div className="bg-theme-white dark:bg-dark dark:border dark:border-[#334155] rounded-md px-3 py-2">
              <div className="w-full h-16 justify-between items-center flex -ml-4">
                <img className="h-16 w-24" src={Logo_1} alt="Logo" style={{ height: "20px", marginLeft: "10px" }} />
                <h1 className="font-nunito dark:text-white text-xl text-center text-nowrap">
                  Swap Crypto
                </h1>
                <div className="w-fit h-fit dark:bg-[#374357] dark:border dark:text-white dark:border-[#1f252e] border-1 p-2 rounded-full border-[#000]">
                  <BsFillGearFill />
                </div>
              </div>
              <div className="">
                <p className="font-nunito dark:text-dark-text">Asset</p>
                <Select
                  disabled
                  placeholder='Select Assets'
                  size="large"
                  className="w-full"
                  defaultValue="lucy"
                  options={[
                    {
                      value: "lucy",
                      label: "BTC",
                    },
                    {
                      value: "One",
                      label: "one",
                    },
                    {
                      value: "two",
                      label: "two",
                    },
                    {
                      value: "three",
                      label: "three",
                    },
                  ]}
                />
                <InputNumber disabled type="number" size="large" placeholder="0.00" className="w-full my-2" />
              </div>
              <div className="d-flex justify-content-center my-1">
                <img src={ph_swap} height="40px" width="40px" alt="" />
              </div>
              <div className="main">
                <p className="font-nunito dark:text-dark-text">Asset</p>
                <Select disabled
                  className="w-full"
                  placeholder='Select Assets'
                  defaultValue="lucy"
                  size="large"
                  options={[
                    {
                      value: "lucy",
                      label: "BTC",
                    },
                    {
                      value: "One",
                      label: "one",
                    },
                    {
                      value: "two",
                      label: "two",
                    },
                    {
                      value: "three",
                      label: "three",
                    },
                  ]}
                />
                <InputNumber disabled type="number" placeholder="0.00" size="large" className="w-full my-2" />
              </div>
              <div className="row my-2">
                <div className="flex my-1 justify-between">
                  <h1 className="font-nunito dark:text-dark-text font-semibold">Exchange Rate</h1>
                  <h1 className="font-nunito dark:text-dark-text">-</h1>
                </div>
                <div className="flex my-1 justify-between">
                  <h1 className="font-nunito dark:text-dark-text font-semibold">Offered by</h1>
                  <h1 className="font-nunito dark:text-dark-text">-</h1>
                </div>
                <div className="flex my-1 justify-between">
                  <h1 className="font-nunito dark:text-dark-text font-semibold">Network Fee</h1>
                  <h1 className="font-nunito dark:text-dark-text">-</h1>
                </div>
                <div className="flex my-1 justify-between">
                  <h1 className="font-nunito dark:text-dark-text font-semibold">Wallet Fee</h1>
                  <h1 className="font-nunito dark:text-dark-text">-</h1>
                </div>
                <div className="flex my-1 justify-between">
                  <h1 className="font-nunito dark:text-dark-text font-semibold">Total</h1>
                  <h1 className="font-nunito dark:text-dark-text">-</h1>
                </div>
                <div className='w-full px-2'>
                  <Button disabled className='bg-hoverUser w-full font-nunito' size="large">
                    Swap
                  </Button>
                </div>
              </div>
            </div>
            <div className="bg-theme-white dark:bg-dark dark:border dark:border-[#334155] rounded-md mt-4 p-2">
              <div className="flex justify-between">
                <h1 className="font-nunito font-bold text-2xl text-nowrap dark:text-white">
                  My Assets
                </h1>
                <Link to='/user/assets'>
                  <Button className="bg-[#E6E8F0] dark:bg-dark-body">View All</Button>
                </Link>
              </div>
              {assets ? Array.isArray(assets) && assets?.slice(0, 5)?.map(item => {
                return (
                  <div key={item?.id} className="flex items-center justify-between my-2">
                    <div className="flex gap-1 items-center">
                      <div className="w-12 h-12 bg-transparent border-[0.5px] border-opacity-60 border-gray-400 rounded-full flex justify-center items-center">
                        <img className="w-8 h-8" src={item?.symbol} alt={item?.symbol} />
                      </div>
                      <div>
                        <p className="leading-3 dark:text-white font-nunito font-semibold">{item?.name}</p>
                        <p className="font-nunito font-bold text-gray-400 text-xs">{`${item?.amount} ${item?.short_name}`}</p>
                      </div>
                    </div>
                    <Button onClick={() => handleNavigate(item)} className="bg-[#E6E8F0] dark:bg-dark-body"><AiOutlineArrowRight /></Button>
                  </div>
                )
              }) :
                <div className="w-full flex justify-center my-4">
                  <Spin size='default' />
                </div>}
            </div>
            <br />
            <div className="bg-theme-white dark:bg-dark dark:border dark:border-[#334155] rounded-md px-2 py-4">
              <div className="w-full flex justify-between items-center">
                <h1 className="font-nunito dark:text-white font-bold text-xl">
                  Latest Transactions
                </h1>
                <Link to='/user/transaction'>
                  <Button className="bg-[#E6E8F0]  dark:bg-dark-body">View All</Button>
                </Link>
              </div>
              {transactions ? Array.isArray(transactions) && transactions?.slice(0, 3)?.map(item => {
                return (
                  <div className="w-full h-full" key={item?.id}>
                    <div className="flex items-center h-full my-2">
                      <div className="arrow me-3 p-3 border-[1px] border-gray-200 dark:border dark:border-[#334155]" style={{ boxShadow: '0px 12px 24px 0px #8F95B226', borderRadius: '10px' }}>
                        <img style={{ width: '14px' }} src={image} alt={image} />
                      </div>
                      <div className="text-content">
                        <h2 className='font-nunito'><span className="dark:text-white" style={{ fontWeight: '900' }}>{item?.asset?.name}</span> <span className="dark:text-dark-text">({item?.status})</span></h2>
                        <p className='font-nunito dark:text-dark-text'>{item?.amount}</p>
                      </div>
                    </div>
                  </div>
                )
              }) :
                <div className="w-full flex justify-center my-4">
                  <Spin />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </section >
  );
};

export default UserDashboard;
