import axios from "axios"
import handleRefreshToken from "./RefreshToken";

const handleGet = async (url) => {
    try {
        const token = localStorage.getItem('token');
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'type': 'Bearer'
        }
        const response = await axios.get(url, { headers })
        if (response) {
            return response?.data;
        }
    }
    catch (error) {
        // if (error?.response?.data?.message === "Unauthenticated.") {
        //     const refreshToken = await handleRefreshToken();
        //     if (refreshToken) {
        //         const token = localStorage.getItem('token');
        //         const headers = {
        //             'Authorization': 'Bearer ' + token,
        //             'Content-Type': 'application/json',
        //             'type': 'Bearer'
        //         }
        //         const response = await axios.get(url, { headers })
        //         if (response) {
        //             return response?.data;
        //         }
        //     }
        //     return;
        // }
        console.log(error)
    }
}

export default handleGet;