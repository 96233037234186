import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom"
import { changeHeader } from '../../../redux/reducer/headerSlice';
import trans_pic from "../../../assests/images/payment_image.png";
import { Button } from "antd";



const Dashboard = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(changeHeader('Admin Dashboard'));
    }, [])

    return (
        <main className='w-full h-full'>
            <div className="w-full h-full flex flex-col items-center">
                <img className="max-w-lg w-full max-h-[520px] my-4" src={trans_pic} alt="trans_pic" />
                <p className="text-2xl font-nunito font-bold dark:text-white transition-all duration-300">No Ticket Selected</p>
                <p className="w-full max-w-[320px] text-center leading-4 my-2 text-gray-500 dark:text-dark-text">Select a ticket from the list to start chatting...</p>
                <Link to='/admin/support'>
                    <Button size="large" className='my-2 w-48 bg-theme-white dark:bg-dark'>Select Ticket</Button>
                </Link>
            </div>
        </main>
    )
}

export default Dashboard