import React, { useEffect } from "react";
import ph_swap from "../../../assests/images/ph_swap.png";
import group from "../../../assests/icons/Group.png";
import { Button, InputNumber, Select } from "antd";
import { BsFillGearFill } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { changeHeader } from '../../../redux/reducer/headerSlice'
import Logo from '../../../assests/images/PayBack.png';
const SwapCrypto = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeHeader(`${user.name}, Swap Crypto`));
  }, [user])
  return (
    <div>
      <div className="w-full flex justify-center">
        <div className="w-full max-w-2xl bg-theme-white dark:bg-dark dark:border dark:border-[#334155] px-8 pb-4 pt-2 rounded-lg my-4">
          <div className="flex items-center -ml-3">
            <div className="col-md-2 col-2">
              <img className="h-[20px] ml-[10px] " src={Logo} alt="group" />
            </div>
            <div className="col-md-8 col-8">
              <h1 className="font-nunito text-2xl dark:text-gray-400 text-center text-nowrap">
                Swap Crypto
              </h1>
            </div>
            <div className="col-md-2 col-2 d-flex justify-end">
              <p className="dark:text-gray-400"><BsFillGearFill size={28} /></p>
            </div>
          </div>
          <div className="mt-4">
            <p className="font-nunito dark:text-gray-400">Asset</p>
            <Select
              disabled
              size="large"
              className="w-100"
              defaultValue="btc"
              style={{
                width: 120,
              }}
              options={[
                {
                  value: "btc",
                  label: "BTC",
                },
                {
                  value: "One",
                  label: "one",
                },
                {
                  value: "two",
                  label: "two",
                },
                {
                  value: "three",
                  label: "three",
                },
              ]}
            />
            <InputNumber disabled type="number" placeholder="0.00" className="w-full my-2" size="large" />
          </div>
          <div className="w-full flex justify-center my-1">
            <img src={ph_swap} height="40px" width="40px" alt="" />
          </div>
          <div className="">
            <p className="font-nunito dark:text-gray-400">Asset</p>
            <Select
              disabled
              size="large"
              className="w-100"
              defaultValue="btc"
              style={{
                width: 120,
              }}
              options={[
                {
                  value: "btc",
                  label: "BTC",
                },
                {
                  value: "One",
                  label: "one",
                },
                {
                  value: "two",
                  label: "two",
                },
                {
                  value: "three",
                  label: "three",
                },
              ]}
            />
            <InputNumber disabled className="w-full my-2" placeholder="0.00" size="large" />
          </div>
          <div className="">
            <div className="flex justify-between my-2 items-center">
              <h1 className="font-nunito font-medium dark:text-gray-400">Exchange Rate</h1>
              <h1 className="font-nunito font-medium dark:text-gray-400">-</h1>
            </div>
            <div className="flex justify-between my-2 items-center">
              <h1 className="font-nunito font-medium dark:text-gray-400">Offered by</h1>
              <h1 className="font-nunito font-medium dark:text-gray-400">-</h1>
            </div>
            <div className="flex justify-between my-2 items-center">
              <h1 className="font-nunito font-medium dark:text-gray-400">Network Fee</h1>
              <h1 className="font-nunito font-medium dark:text-gray-400">-</h1>
            </div>
            <div className="flex justify-between my-2 items-center">
              <h1 className="font-nunito font-medium dark:text-gray-400">Wallet Fee</h1>
              <h1 className="font-nunito font-medium dark:text-gray-400">-</h1>
            </div>
            <div className="flex justify-between my-2 items-center">
              <h1 className="font-nunito font-medium dark:text-gray-400">Total</h1>
              <h1 className="font-nunito font-medium dark:text-gray-400">-</h1>
            </div>
            <div className="d-flex justify-content-center">
              <Button disabled size="large" className="w-full bg-hoverUser h-10 font-nunito font-semibold text-lg rounded-lg my-2" >Swap</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwapCrypto;
