import React, { useEffect, useState } from 'react';
// import "./index.css";
import image from '../../../assests/images/Arrow 2.png';
import eyeimage from '../../../assests/images/akar-icons_eye.png';
import { Input, Modal, Pagination, Spin } from 'antd';
import trans_pic from '../../../assests/images/payment_image.png'
import { BiSearch, BiUserCircle } from 'react-icons/bi';
import { AiOutlineLink } from 'react-icons/ai'
import { GoCopy } from 'react-icons/go'
import { changeHeader } from '../../../redux/reducer/headerSlice';
import { useDispatch } from 'react-redux';
import { Button, Space } from 'antd';
import handlePost from '../../../utils/Post';
import handleGet from '../../../utils/Get';
import { BASE_URL } from '../../../utils/config';
import dayjs from 'dayjs';
import TextArea from 'antd/es/input/TextArea';
import { Link } from 'react-router-dom';



const Transaction = () => {

    const [currentPage, setCurrentPage] = useState(1);
    const [metaData, setMetaData] = useState('');
    const [isModalOpen, setIsModalOpen] = useState({ open: false, data: '' });
    const [transactions, setTransactions] = useState('');
    const [note, setNote] = useState('');
    const [loading, setLoading] = useState({ accept: false, decline: false });
    const dispatch = useDispatch();
    const [userName, setUserName] = useState('');

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        dispatch(changeHeader('Admin Dashboard'));
    }, [])

    const fetchTransactions = async () => {
        const data = await handleGet(`${BASE_URL}transaction/get/all?page_number=${currentPage}`);
        setTransactions(data?.transactions);
        setMetaData(data?.meta)
    }

    useEffect(() => {
        if (isModalOpen?.open) {
            const fetchUser = async () => {
                const user = await handleGet(`${BASE_URL}get/user/detail/${isModalOpen?.data?.user_id}`)
                setUserName(user?.user?.email);
            }
            fetchUser();
        }
    }, [isModalOpen])

    useEffect(() => {
        fetchTransactions();
    }, [currentPage])

    const handleAccept = async () => {
        setLoading({ accept: true, decline: false });
        try {
            const response = await handlePost(`${BASE_URL}transaction/accept/${isModalOpen?.data?.id}`, { 'note': note })
            console.log(response)
            if (response) {
                setIsModalOpen(false);
                setNote('')
                fetchTransactions();
            }
            setLoading({ accept: false, decline: false });
        } catch (err) {
            console.log(err)
            setLoading({ accept: false, decline: false });
        }
    }
    const handleDecline = async () => {
        setLoading({ accept: false, decline: true });

        try {
            const response = await handlePost(`${BASE_URL}transaction/decline/${isModalOpen?.data?.id}`, { 'note': note })
            if (response) {
                setIsModalOpen(false);
                setNote('')
                setLoading({ accept: false, decline: false });
                fetchTransactions();
            }
        } catch (error) {
            console.log(error)
            setLoading({ accept: false, decline: false });

        }
    }

    return (

        <>
            <Input className='max-w-xs h-10 shadow-md rounded-xl' placeholder='Filter Transactions' prefix={<BiSearch size={24} color='#9AA0AC' />} />
            <div className='w-full h-full overflow-x-auto p-2'>
                {
                    transactions ? transactions?.length ? transactions?.map(item => {
                        return (
                            <div className='w-full min-w-[480px]' key={item?.id}>
                                <div className="flex items-center h-24 shadow-md rounded-xl mt-4 px-4 dark:bg-dark dark:border dark:border-[#334155] bg-theme-white">
                                    <div className="arrow me-3 p-3 border-[1px] border-gray-200 dark:border dark:border-[#334155]" style={{ boxShadow: '0px 12px 24px 0px #8F95B226', borderRadius: '10px' }}>
                                        <img style={{ width: '17px' }} src={image} alt="Arrow" />
                                    </div>
                                    <div className="text-content">
                                        <h2 className='font-nunito dark:text-white'><span style={{ fontWeight: '900' }}>{item?.asset?.name}</span> <span className='dark:text-dark-text text-gray-500' >({item?.status})</span></h2>
                                        <div className='flex gap-2'>
                                            <p className='font-nunito dark:text-dark-text text-gray-500'>{item?.amount}</p>
                                            <p className='font-nunito dark:text-dark-text text-gray-500'>({item?.type === 'withdraw' ? 'withdrawal' : item?.type})</p>
                                        </div>
                                    </div>
                                    <div className="text-content" style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                                        <p className='font-nunito dark:text-dark-text' style={{ fontSize: '14px' }}>{item?.sent_at ? dayjs(item?.sent_at).format('DD-MM-YYYY hh:mm') : dayjs().format('DD-MM-YYYY hh:mm')}</p>
                                        <div className='p-3'>
                                            <img className='w-8 h-8' src={eyeimage} alt="eyeimage" onClick={() => setIsModalOpen({ open: true, data: item })} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    }) :
                        <div className="flex flex-col items-center">
                            <img className="max-w-xl w-full" src={trans_pic} alt="trans_pic" />
                            <p className='mt-4 text-2xl dark:text-[#fff] font-nunito'>No Transactions Found</p>
                            <p className='dark:text-dark-text text-gray-500 font-nunito font-medium'>Try to add filter or add a new transaction</p>
                        </div>
                        : <div className='w-full h-48 flex justify-center items-end'><Spin size='large' /></div>
                }
            </div>
            {transactions?.length ? <div style={{ display: 'flex', justifyContent: 'center', color: 'red', marginTop: '30px' }}>
                <Pagination
                    current={currentPage}
                    defaultCurrent={1}
                    total={metaData?.total}
                    pageSize={metaData?.per_page}
                    onChange={(page) => setCurrentPage(page)}
                />
            </div> : ''
            }
            <Modal
                width={620}
                centered={true}
                title=''
                open={isModalOpen?.open}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={false}
            >
                <div>
                    <div className=''>
                        <div className='flex items-center flex-wrap gap-4'>
                            <div>
                                <h1 className='font-nunito text-xl md:text-3xl font-bold dark:text-white'>Transaction Details</h1>
                                <div className='flex items-center'>
                                    <BiUserCircle size={24} />
                                    <h1 className='text-center font-bold dark:text-dark-text'>User: {userName ? userName : '____'}</h1>
                                </div>
                            </div>
                            <Link to={`/admin/managment/${isModalOpen?.data?.user_id}`}>
                                <Button size='large' className='flex items-center'>
                                    <AiOutlineLink size={20} className='me-2' /> User Profile
                                </Button>
                            </Link>
                        </div>
                    </div>
                    <div className='grid grid-cols-3 items-center font-nunito my-2 mt-4'>
                        <div className='col-span-3 min-[560px]:col-span-2'>
                            <h1 className='text-gray-600 dark:text-dark-text'>Transaction ID</h1>
                            <div className='flex items-center gap-2'>
                                <p className='font-bold text-[10px] sm:text-xs md:text-sm w-fit text-nowrap'>{`${isModalOpen?.data?.uuid}`}</p>
                                <GoCopy size={20} className='w-5 h-5' />
                            </div>
                        </div>
                        <div className='col-span-3 md:col-span-1 mt-2 md:mt-0'>
                            <h1 className='text-gray-600 dark:text-dark-text'>Transaction Hash</h1>
                            <div className='flex gap-2 items-center'>
                                <p className='font-bold'>N/A</p>
                                <GoCopy size={20} className='' />
                            </div>
                        </div>

                    </div>
                    <div className='grid grid-cols-3 my-2 items-center'>
                        <div className='col-span-2'>
                            <h1 className='text-gray-600 dark:text-dark-text'>TimeStamp</h1>
                            <div className='flex items-center'>
                                <p className='font-bold'>{isModalOpen?.data?.sent_at ? dayjs(isModalOpen?.data?.sent_at).format('DD-MM-YYYY hh:mm:ss') : dayjs().format('DD-MM-YYYY hh:mm:ss')}</p>
                            </div>
                        </div>
                        <div className='col-span-1'>
                            <h1 className='text-gray-600 dark:text-dark-text'>Block</h1>
                            <div className='flex items-center'>
                                <p className='font-bold'>N/A</p>
                            </div>
                        </div>
                    </div>
                    <div className='grid grid-cols-3 items-center my-2'>
                        <div className='col-span-3'>
                            <h1 className='text-gray-600 dark:text-dark-text'>From</h1>
                            <div className='flex items-center gap-2'>
                                <p className='font-bold text-xs sm:text-sm text-nowrap'>{isModalOpen?.data?.from_address ?? 'N/A'}</p>
                                <GoCopy size={20} />
                            </div>
                        </div>
                        <div className='col-span-3 my-2'>
                            <h1 className='text-gray-600 dark:text-dark-text'>To</h1>
                            <div className='flex items-center gap-2'>
                                <p className='font-bold text-xs sm:text-sm text-nowrap'>{isModalOpen?.data?.to_address ?? 'N/A'}</p>
                                <GoCopy size={20} />
                            </div>
                        </div>

                    </div>
                    <div className='grid grid-cols-3 items-center my-2'>
                        <div className='col-span-2'>
                            <h1 className='text-gray-600 dark:text-dark-text'>Value</h1>
                            <div className='flex items-center gap-2'>
                                <p className='font-bold'>{isModalOpen?.data?.amount ?? 'N/A'}</p>
                                <GoCopy size={20} />
                            </div>
                        </div>
                        <div className='col-span-1'>
                            <h1 className='text-gray-600 dark:text-dark-text'>Status</h1>
                            <div className='flex items-center gap-2'>
                                <p className={`${"pending" == "pending" ? "text-[#7EBA00]" : "text-red-400"} font-bold capitalize`}>{isModalOpen?.data?.status}</p>
                            </div>
                        </div>

                    </div>
                    <div className='grid grid-cols-3 my-2'>
                        <div className='col-span-3 min-[560px]:col-span-2'>
                            <h1 className='text-gray-600 dark:text-dark-text'>Actions</h1>
                            <div className='flex items-center'>
                                <p className='font-bold'>You can approve or reject this transaction.</p>
                            </div>
                        </div>
                        <div className='col-span-3 min-[560px]:col-span-1'>
                            <h1 className='text-gray-600 dark:text-dark-text'>Note</h1>
                            <div className='flex items-center mt-2'>
                                <TextArea
                                    onChange={e => setNote(e.target.value)}
                                    value={note}
                                    rows={2}
                                    placeholder="Enter Note"
                                    className="placeholder-gray-400::placeholder" // Set the placeholder color
                                />
                            </div>
                        </div>
                    </div>
                    {isModalOpen?.data?.status === 'pending' && <div>
                        <Space wrap>
                            <Button loading={loading?.decline} className='min-w-[90px]' onClick={handleDecline} size='large'>
                                Decline
                            </Button>
                            <Button loading={loading?.accept} className='min-w-[90px] bg-[#6c5dd3] text-white' onClick={handleAccept} size='large'>
                                Approve
                            </Button>
                        </Space>

                    </div>}
                </div>

            </Modal >
        </>
    );
}

export default Transaction;
